import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Head from '../../components/header';
import { getItem, hexToRGBA } from '../../utils';
import chevronRight from '../../images/chevron-right.svg';
// import chevronRight from './images/call-us.svg';
import changePassword from './images/mail.svg';
// import chevronRight from './images/mail.svg';
// import chevronRight from './images/notification.svg';
import telegram from './images/telegram.svg';
import walletSettings from './images/call-us.svg';
import { globals } from '../../utils/themes';
import { darkTheme, lightTheme } from "../../utils/themes";
import Switch from '@mui/material/Switch';
import Nav from '../../components/nav';
import { GetPages } from '../../utils/endpoints';
import useIsDesktop from '../../customHooks/useDesktop';
import Usercard from '../../components/usercard';
import DesktopNav from '../../components/desktopNav';




const useStyles = makeStyles((theme) => ({
    root: {
        '& a': {
            textDecoration: 'none'
        },
        '@media (min-width:1000px)': {
            marginInline: 'auto', width: 1000,
            padding: '2rem',
            backgroundColor: theme.palette.background.default,
            borderRadius: 20,
        }
    },
    setting: {
        display: 'flex', alignItems: 'center',
        justifyContent: 'space-between', width: '100%', backgroundColor: theme.palette.primary.tintMain,
        padding: '20px 20px', borderRadius: globals.borderRadius, marginBottom: 15,

        '& span': {
            color: theme.palette.text.primary, fontWeight: 500, fontSize: 13,
        },
        '& p': {
            color: theme.palette.text.secondary, fontSize: 11, padding: '4px 0'
        },
        '& .left': {
            alignItems: 'center', justifyContent: 'space-between', gap: 10, display: 'flex',
            '& .icon-cont': {
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                padding: '7px', borderRadius: '20px', backgroundColor: hexToRGBA(theme.palette.background.default, 0.3)
            }
        },

    }


}));




export default memo(function CustomerSupport({ setUserTheme = () => null }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState({ type: 'error', message: '', status: false });

    const [formData, setFormData] = useState({
        whatsapp: '',
        email: '',
        telegram: ''
    });

    useEffect(() => {
        GetPages('contact').then((res) => {
            if (res?.data?.data?.PageData) {
                const { whatsapp, email, telegram } = res.data.data.PageData;
                setFormData({
                    whatsapp: whatsapp,
                    email: email,
                    telegram: telegram
                });
            }

        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    const isDesktop = useIsDesktop();


    return (
        <div style={isDesktop?{ paddingTop:0}:{ paddingTop: 36 }}>
           {!isDesktop && <Head backTo='/dashboard/settings' currentPageTitle='Customer Support' />}
            {!isDesktop && <Head backTo='/dashboard/settings' currentPageTitle='Change Password' />}
            {isDesktop && <><Usercard /><br /><DesktopNav /><br /></>}

            <div className={`${classes.root}`}>

                <a target="_blank" href={`https://api.whatsapp.com/send/?phone=${formData.whatsapp}&text=Hello FlashUSDT, I want to...&type=phone_number&app_absent=0`}>
                    <div className={`${classes.setting}`} tabIndex={-1}>
                        <div className='left'>
                            <div className='icon-cont'>
                                <img src={walletSettings} alt=" " />
                            </div>
                            <div>
                                <span>WhatsApp</span>
                                <p>{formData.whatsapp}</p>
                            </div>
                        </div>
                        <img src={chevronRight} alt="" />
                    </div>
                </a>

                <a href={`mailto:${formData.email}`}>
                    <div className={`${classes.setting}`} tabIndex={-1}>
                        <div className='left'>
                            <div className='icon-cont'>
                                <img src={changePassword} alt=" " />
                            </div>
                            <div>
                                <span>Send us an email (24/7)</span>
                                <p>{formData.email}</p>
                            </div>
                        </div>
                        <img src={chevronRight} alt="" />
                    </div>
                </a>
                <a href={`tg://${formData.telegram}`} target="_blank">
                    <div className={`${classes.setting}`}>

                        <div className='left'>
                            <div className='icon-cont'>
                                <img src={telegram} alt=" " />
                            </div>
                            <div>
                                <span>Telegram chat</span>
                                <p>{formData.telegram}</p>
                            </div>
                        </div>
                        <img src={chevronRight} alt="" />
                    </div>

                </a>


            </div>
            {!isDesktop && <Nav />}
        </div>
    );


});