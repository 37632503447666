import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { globals } from '../utils/themes';
import { commarize, USD } from '../utils/usdt/usdt';


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        bottom: 40, left: '50%', transform: 'translateX(-50%)',
        '& a': {
            textDecoration: 'none',
            color: theme.palette.primary.main
        }
    }

}));

export default memo(function DesktopFooter({style}) {
    const classes = useStyles();
    return (<small style={style} className={classes.root}>Need help? Contact <a href="mailto:support@flashusdtpro.com">FlashUSDT Support</a></small>);


})