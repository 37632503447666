import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import Button from '../components/button';
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { globals } from '../utils/themes';
import { formatWalletAddress } from '../utils';

const useStyles = makeStyles((theme) => ({
  wrapper:{
    display:'flex',flexDirection:'column', justifyContent:'space-between', alignItems:'center', height:'calc(100dvh - 50px)'
    , "@media (min-width:1000px)": {
            width: '1000px', marginInline:'auto',backgroundColor:theme.palette.background.default,
            padding:'2rem',borderRadius:20, minHeight:300, marginTop: 0,
        }
  },
  root:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection:'column',
    padding: theme.spacing(1), width:'100%',
    color: theme.palette.text.primary,
    '& a':{
      textDecoration: 'none',
      color: theme.palette.primary.main, fontWeight:600,
    },
   
  },
  top:{
    width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap:5,
     marginBottom:'20px', flexDirection: 'column',
     '&  h2':{
      marginTop: '10px',
     }
  },
  cta:{
    width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
     marginBottom:'20px',gap:30, padding:'0 10px',
  },

  details: {
    display: 'flex',justifyContent: 'center', alignItems: 'flex-start',
    flexDirection: 'column', width:'100%', padding: theme.spacing(3), backgroundColor:theme.palette.primary.tintMain, borderRadius:globals.borderRadius,  fontSize: 12, gap:30, marginTop:40,

    '& h3':{
      color: theme.palette.text.primary, fontWeight:700, fontSize:17, marginBottom:10,
    },
    '& .row':{
      display: 'flex',justifyContent: 'space-between', alignItems: 'center', width: '100%',

      '& .completed':{
        color: theme.palette.primary.green,
      },
      '& .failed':{
        color: theme.palette.primary.red,
      },
      '& > :last-child':{
       maxWidth:100,
       whiteSpace: 'nowrap',
      //  overflow: 'hidden',
      //  textOverflow: 'ellipsis', 
      }
    },
    '& .chart':{
      width:'100%',height:200,overflow: 'hidden',
      '& img': {
        width:'100%',height:'100%', objectFit: 'contain',
      },
      
    }
    
  
  }
  
   
  
}));

export default memo(function ConfirmTxn({TxData,CTA}){
  const classes = useStyles();
  const nav = useNavigate();
  const [coin,setCoin]=useState('');

  return (
    <div className={classes.wrapper}>

      <div className={classes.root}> 
        <h3 style={{marginBottom:10}}> Confirm Transaction</h3>

        <div className={classes.top}>
          <p>{TxData.coin}</p>
          <h2>{TxData.amount}</h2>
        </div>


        <div className={classes.details}>
          <div className='row'>
            <span>From</span>
            <span>{TxData.from}</span> 
          </div>

          <div className='row'>
            <span>To</span>
            <span>{formatWalletAddress(TxData.to)}</span>
          </div>

          <div className='row'>
            <span>Total</span>
            {TxData.amount}
          </div>

        </div>


    </div>      
        <div className={classes.cta}>
          <Button text='Reject' variant='secondary' onClick={()=>nav('/dashboard')}/>
          <Button text='Sign' onClick={()=>CTA({id:1,data:TxData})}/>
        </div>
      
    </div>
  );
});

