import React, { memo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Popup from './popup';
import useIsDesktop from '../customHooks/useDesktop';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        zIndex: 1000,
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', marginTop: '4rem', gap: 30,
        backgroundColor: theme.palette.background.default,
        borderRadius: '2.3rem 2.3rem 0 0', width: '100%', padding: theme.spacing(7, 2), minHeight: 200,

    },
    innerBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', gap: 30,
        width: '100%',
        '& svg ': {
            width: '40%', height: '40%'
        },
        '& svg path#circle': {
            fill: theme.palette.icon.main
        },
        '& svg path#check': {
            fill: theme.palette.primary.main
        }
    },
    titleSection: {
        textAlign: 'center',
        '& h3': {
            color: theme.palette.text.primary,
        },
        '& p': {
            color: theme.palette.text.secondary,
            padding: theme.spacing(1, 2),
            fontSize: '14px',
        },
    },
}));

export default memo(function FlyOut({ title, style, descr, children }) {
    const classes = useStyles();
    const isDesktop = useIsDesktop(512);
    // const [show,setShow]=useState(true);

    return (
        <>
            {isDesktop ? (<Popup display={true}>
                <div className={`${classes.body}`}>
                    <div className={`${classes.innerBody}`}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="circle" opacity="0.4" d="M1.24988 12.0002C1.24988 17.9373 6.06282 22.7502 11.9999 22.7502C17.9369 22.7502 22.7499 17.9373 22.7499 12.0002C22.7499 6.06318 17.9369 1.25024 11.9999 1.25024C6.06282 1.25024 1.24988 6.06318 1.24988 12.0002Z" fill="#141B34" />
                            <path id="check" className="animated bounceIn delay-0-6s" fillRule="evenodd" clipRule="evenodd" d="M22.9374 4.65241C23.1295 5.1702 22.8655 5.7457 22.3477 5.93782C21.2889 6.33068 20.1272 7.14841 18.9513 8.22885C17.7864 9.29902 16.664 10.574 15.6792 11.8107C14.6964 13.0449 13.863 14.2254 13.275 15.0981C12.9813 15.5339 12.7497 15.8917 12.5922 16.1394C12.5135 16.2632 12.4534 16.3594 12.4133 16.424L12.3685 16.4966L12.3577 16.5142L12.3553 16.5182C12.1716 16.8209 11.841 17.0048 11.4869 17.0002C11.1327 16.9956 10.8074 16.804 10.6316 16.4964C9.6824 14.8352 8.89824 14.0873 8.42642 13.757C8.19196 13.5929 8.03245 13.5302 7.9623 13.5079C7.94574 13.5026 7.93357 13.4994 7.92593 13.4976C7.40818 13.4597 6.99988 13.0277 6.99988 12.5003C6.99988 11.948 7.44759 11.5003 7.99988 11.5003V12.5003C7.99988 11.5003 8.00218 11.5003 8.00218 11.5003L8.00451 11.5003L8.00929 11.5003L8.01929 11.5005L8.04106 11.5011C8.05635 11.5016 8.07316 11.5025 8.09147 11.5039C8.12811 11.5066 8.17062 11.5112 8.21868 11.5185C8.31498 11.5331 8.43248 11.5587 8.56871 11.602C8.8423 11.6891 9.1828 11.8452 9.57334 12.1185C10.1331 12.5104 10.79 13.1389 11.5058 14.1453C11.5416 14.0916 11.5785 14.0367 11.6163 13.9805C12.225 13.0772 13.0901 11.8515 14.1147 10.5648C15.1372 9.28067 16.331 7.92028 17.5981 6.75607C18.8541 5.60213 20.2403 4.58653 21.652 4.06272C22.1698 3.87061 22.7453 4.13462 22.9374 4.65241Z" fill="#141B34" />
                        </svg>

                        <div className={classes.titleSection}>
                            <h3>{title}</h3>
                            <p>{descr}</p>
                        </div>

                        {/* button */}
                        {children}
                    </div>
                </div>
            </Popup>) :
                (<div className={classes.backdrop}>
                    <div className={`${classes.body}`}>
                        <div className={`${classes.innerBody} animated slideInUp faster delay-0-2s`}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="circle" opacity="0.4" d="M1.24988 12.0002C1.24988 17.9373 6.06282 22.7502 11.9999 22.7502C17.9369 22.7502 22.7499 17.9373 22.7499 12.0002C22.7499 6.06318 17.9369 1.25024 11.9999 1.25024C6.06282 1.25024 1.24988 6.06318 1.24988 12.0002Z" fill="#141B34" />
                                <path id="check" className="animated bounceIn delay-0-6s" fillRule="evenodd" clipRule="evenodd" d="M22.9374 4.65241C23.1295 5.1702 22.8655 5.7457 22.3477 5.93782C21.2889 6.33068 20.1272 7.14841 18.9513 8.22885C17.7864 9.29902 16.664 10.574 15.6792 11.8107C14.6964 13.0449 13.863 14.2254 13.275 15.0981C12.9813 15.5339 12.7497 15.8917 12.5922 16.1394C12.5135 16.2632 12.4534 16.3594 12.4133 16.424L12.3685 16.4966L12.3577 16.5142L12.3553 16.5182C12.1716 16.8209 11.841 17.0048 11.4869 17.0002C11.1327 16.9956 10.8074 16.804 10.6316 16.4964C9.6824 14.8352 8.89824 14.0873 8.42642 13.757C8.19196 13.5929 8.03245 13.5302 7.9623 13.5079C7.94574 13.5026 7.93357 13.4994 7.92593 13.4976C7.40818 13.4597 6.99988 13.0277 6.99988 12.5003C6.99988 11.948 7.44759 11.5003 7.99988 11.5003V12.5003C7.99988 11.5003 8.00218 11.5003 8.00218 11.5003L8.00451 11.5003L8.00929 11.5003L8.01929 11.5005L8.04106 11.5011C8.05635 11.5016 8.07316 11.5025 8.09147 11.5039C8.12811 11.5066 8.17062 11.5112 8.21868 11.5185C8.31498 11.5331 8.43248 11.5587 8.56871 11.602C8.8423 11.6891 9.1828 11.8452 9.57334 12.1185C10.1331 12.5104 10.79 13.1389 11.5058 14.1453C11.5416 14.0916 11.5785 14.0367 11.6163 13.9805C12.225 13.0772 13.0901 11.8515 14.1147 10.5648C15.1372 9.28067 16.331 7.92028 17.5981 6.75607C18.8541 5.60213 20.2403 4.58653 21.652 4.06272C22.1698 3.87061 22.7453 4.13462 22.9374 4.65241Z" fill="#141B34" />
                            </svg>


                            <div className={classes.titleSection}>
                                <h3>{title}</h3>
                                <p>{descr}</p>
                            </div>

                            {/* button */}
                            {children}
                        </div>
                    </div>
                </div>)

            }
        </>
    );
});