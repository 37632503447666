import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Head from '../../components/header';
import { getItem, saveItem } from '../../utils';
import logout from '../../images/Logout.svg'
import customerSupport from '../../images/customer-support.svg';
import moon from '../../images/moon.svg';
import preferences from '../../images/preferences.svg';
import accountStatus from '../../images/account-status.svg';
import personalServer from '../../images/personal-server.svg';
import importWallet from '../../images/import-wallet.svg';
import uploadPlugin from '../../images/upload-plugin.svg';
import security from '../../images/security-settings.svg';
import bell from '../../images/bell.svg';
import abt from '../../images/information-square.svg';
import chevronRight from '../../images/chevron-right.svg';
import edit from '../../images/edit.svg';
import { globals } from '../../utils/themes';
import Usercard from '../../components/usercard';
import { darkTheme, lightTheme } from "../../utils/themes";
import Switch from '@mui/material/Switch';
import Nav from '../../components/nav';
import { ProfileUpdating } from '../../utils/endpoints';
import Popup from '../../components/popup';
import Button from '../../components/button';
import nope from '../../images/gradient-hate-emoji-illustration 1.svg';
import { useTheme } from '@emotion/react';
import useIsDesktop from '../../customHooks/useDesktop';
import DesktopNav from '../../components/desktopNav';

export const ACCOUNT_TYPE ={
    FREE: 'FREE',
    DEMO: 'DEMO',
    PREMIUM: 'PREMIUM'
}


const useStyles = makeStyles((theme) => ({
    root:{
        '& .icon-cont':{
            width: '50px',
            height: '50px',
            cursor: 'pointer',
            borderRadius: '50%',
            display:'flex',justifyContent: 'center',alignItems: 'center',
            backgroundColor: theme.palette.primary.tintMain
            
        },
        "@media only screen and (min-width:1000px)":{
            overflowY: 'scroll', 
            // height:'calc(100vh - 190px)', 
            backgroundColor: theme.palette.background.default, width:1000, marginInline:'auto', padding:'2rem',borderRadius: '20px',
        },
    },
    section:{
        display: 'flex', justifyContent: 'space-between', flexDirection: 'column',gap: 30,
        backgroundColor: theme.palette.primary.tintMain,
        padding:'20px 30px',borderRadius: globals.borderRadius, margin:'20px 0',
        "@media only screen and (min-width:1000px)":{
            backgroundColor: 'transparent', margin:0, padding:'10px 30px',gap: 20,
        },
    },
    setting:{
        display:'flex', alignItems: 'center',
        justifyContent:'space-between', width:'100%',
        
        '& span':{
            color: theme.palette.text.primary, fontWeight:500, fontSize:13,
        },
        '& .left':{
            justifyContent:'space-between',gap:20, display:'flex',
        },
        '& .logout':{
            color: theme.palette.primary.red +' !important',
        },
        "@media only screen and (min-width:1000px)":{
            backgroundColor: theme.palette.primary.tintMain,
           border: '0.8px solid #d3d3d399',
            padding:'10px', borderRadius:'50px', maxHeight:40, cursor:'pointer',
        },
    },
    popupText:{
        fontSize:'15px',
        color: theme.palette.text.primary,
        fontWeight:500, marginBottom:20, marginTop:40,
    },
    popupBtns:{
        display:'flex', alignItems: 'center', gap:20,
        justifyContent:'space-between', width:'100%',
    }
    ,
    footnote:{
        // position:'fixed', bottom:15, left:'50%', transform:'translateX(-50%)',
        textAlign:'center',
        color:theme.palette.text.secondary, fontSize:12,
    }
       

}));


  

export default memo(function Settings({setUserTheme=()=>null}) {
    // const [settings, setSettings] = useState([]);
    const classes= useStyles();
    const nav =useNavigate();
    const [btnLoading,setBtnLoading] = useState(false);
    const [user, setUser] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [popupType, setPopupType] = useState('LOGOUT');

    const theme = useTheme();

    const toggleTheme = ()=> {
        setUserTheme((prevTheme) => (prevTheme === darkTheme ? lightTheme : darkTheme));
        saveItem('__FLASHUSDT_U.THEME__',(theme.palette.mode === 'light'?'dark':'light'));
      };

      useEffect(()=>{
        const u = getItem('__FLASHUSDT_USER__');        
        if(u){
            setUser(u);
        }
            
      },[]);

      const isDarkMode = theme.palette.mode ? theme.palette.mode === 'dark' : false;
      
      const Logout = useCallback(()=>{
        setBtnLoading(true);
        ProfileUpdating({LoggedIn:0}, getItem('__FLASHUSDT__')).then((res)=>{
            if(res.data.data?.Email){
                delete res.data.data.Email;
            }             
            saveItem('__FLASHUSDT_USER__',res.data.data);
            nav('/signin'); //swr - for reducing loadings
            //IVF - fertility for overaged women
        }).catch(err => {
            console.log(err);
        }).finally(()=>{
             setBtnLoading(false);
        });

      },[])

      const confirmElegibility = useCallback((route ='', permittedTypes=[ACCOUNT_TYPE.PREMIUM, ACCOUNT_TYPE.DEMO])=> {
        setPopupType('ELEGIBLE');
        if(permittedTypes.includes(user.AccountType)){
          //proceed to destination
          nav(route);
        }else{
          setShowPopup(true);
        }
      });
      
const isDesktop = useIsDesktop();
const [showNotification, setShowNotification] = useState(false);
const notificationDisplay = ()=>{
    isDesktop ? setShowNotification(true) : nav('/dashboard/notification',{state:{prevRoute:'/dashboard/settings'}})
}


    return(
        <div style={isDesktop?{paddingTop:0}:{paddingTop:36}}>
           {!isDesktop &&  <Head backTo='/dashboard' currentPageTitle='Settings'/>}

            <Usercard showAside={showNotification} x={(s)=>setShowNotification(s)} withCont={!isDesktop} icon={(<div tabIndex={-1} onClick={()=>nav('/dashboard/profile')} role='button' className='icon-cont'><img src={edit} alt="" /></div>)}/>
            {isDesktop && <><br /><DesktopNav/></>}
            <div className={`${classes.root}`}>

            <div className={`${classes.section}`}>
                <div className={`${classes.setting}`} tabIndex={-1} onClick={()=>nav('/dashboard/activation')} role='button'>
                    <div className='left'>
                        <img src={accountStatus} alt=" " />
                        <span>Account Status</span>
                    </div>
                    <img src={chevronRight} alt="" />
                </div>

                <div className={`${classes.setting}`} tabIndex={-1} onClick={()=>confirmElegibility('/dashboard/server-import',[ACCOUNT_TYPE.PREMIUM])} role='button'>
                    <div className='left'>
                        <img src={personalServer} alt=" " />
                        <span>Activate Personal server</span>
                    </div>
                    <img src={chevronRight} alt="" />
                </div>

                <div className={`${classes.setting}`} tabIndex={-1} onClick={()=>confirmElegibility('/dashboard/wallet-import',[ACCOUNT_TYPE.PREMIUM])} role='button'>
                    <div className='left'>
                        <img src={importWallet} alt=" " />
                        <span>Import Wallet</span>
                    </div>
                    <img src={chevronRight} alt="" />
                </div>

                <div className={`${classes.setting}`} tabIndex={-1} onClick={()=>confirmElegibility('/dashboard/plugin',[ACCOUNT_TYPE.PREMIUM])} role='button'>
                    <div className='left'>
                        <img src={uploadPlugin} alt=" " />
                        <span>Upload plugin</span>
                    </div>
                    <img src={chevronRight} alt="" />
                </div>
            </div>


            <div className={`${classes.section}`}>
                <div className={`${classes.setting} hide`} tabIndex={-1} onClick={()=>nav('/dashboard/security')} role='button'>
                    <div className='left'>
                        <img src={security} alt=" " />
                        <span>Security Settings</span>
                    </div>
                    <img src={chevronRight} alt="" />
                </div>

                <div className={`${classes.setting}`} tabIndex={-1} onClick={()=>nav('/dashboard/reset-password')} role='button'>
                    <div className='left'>
                        <img src={security} alt=" " />
                        <span>Change Password</span>
                    </div>
                    <img src={chevronRight} alt="" />
                </div>

                <div className={`${classes.setting}`} tabIndex={-1} role='button' onClick={notificationDisplay}>
                    <div className='left'>
                        <img src={bell} alt=" " />
                        <span>Notifications</span>
                    </div>
                    <img src={chevronRight} alt="" />
                </div>

                <div className={`${classes.setting}`}>
                    <div className='left'>
                        <img src={moon} alt=" "  width={19}/>
                        <span>Theme (Light/Dark)</span>
                    </div>
                    <Switch checked={isDarkMode} onChange={toggleTheme}/>
                </div>

                <div className={`${classes.setting} hide`}>
                    <div className='left'>
                        <img src={preferences} alt=" " />
                        <span>Preferences</span>
                    </div>
                    <img src={chevronRight} alt="" />
                </div>

                <div className={`${classes.setting}`} tabIndex={-1} onClick={()=>nav('/dashboard/support')} role='button'>
                    <div className='left'>
                        <img src={customerSupport} alt=" " />
                        <span>Customer support</span>
                    </div>
                    <img src={chevronRight} alt="" />
                </div>

                <div className={`${classes.setting}`} tabIndex={-1} onClick={()=>nav('/dashboard/about')} role='button'>
                    <div className='left'>
                        <img src={abt} alt=" " width={20}/>
                        <span>About</span>
                    </div>
                    <img src={chevronRight} alt="" />
                </div>

                <div className={`${classes.setting}`} tabIndex={-1} onClick={()=>{setShowPopup(true);setPopupType('LOGOUT')}} role='button'>
                    <div className='left'>
                        <img src={logout} alt=" " />
                        <span className='logout'>Logout</span>
                    </div>
                    <img src={chevronRight} alt="" />
                </div>
            </div>


            </div>
           {!isDesktop&& <Nav/>}
            <Popup display={showPopup} setDisplay={setShowPopup}>
                {
                    popupType === 'LOGOUT' && (<><div className={classes.popupText}>Are you sure you want to logout?</div><div className={classes.popupBtns}>
                        <Button text='Logout' onClick={Logout} loading={btnLoading} />
                        <Button text='Cancel' variant='secondary' onClick={()=>setShowPopup(false)} />
                    </div></>)

                }
                {
                    popupType === 'ELEGIBLE' && (<> <img src={nope} alt="No tx illustration" />
                        <div style={{fontSize:12,fontWeight:400,textAlign:'center', margin:'15px 0',color:theme.palette.text.secondary}}> Oops, this feature is not available for your account type. Kindly upgrade to enjoy more offers
                        </div>
                  
                        <Button text='Upgrade now' onClick={()=>nav('/dashboard/activation',{state:{prevRoute:'/dashboard/settings'}})}/>
                  </>)
                }
          
            </Popup>
        </div>
    );


})