import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { globals } from '../utils/themes';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',maxWidth: 500, width: '100%',
    },
    label: {
        color:theme.palette.text.primary
    },
    inputWrapper: {
        position: 'relative',
    },
    input: {
        border: '1px solid transparent',
        padding: '22px 55px 22px 16px',
        fontSize: '1rem',
        color: theme.palette.text.primary,
        borderRadius:globals.borderRadius,
        width:'100%',minHeight:150,
        backgroundColor: '#FFFFFF00',
        border: `1px solid ${theme.palette.primary.stroke}`,
        '&::placeholder': {
            color: theme.palette.text.secondary, fontSize:13,
        },
        '&.error': {
            borderColor: 'red',
        },
        '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
            outline: 'none',
        }
    },
    iconCont: {
        position: 'absolute',
        top: '50%',
        right: '1.3rem',
        cursor: 'pointer',
        transform: 'translateY(-50%)',
        padding: '5px',
        display:'flex',justifyContent: 'center',alignItems:'center',
        '& path':{
            fill: theme.palette.text.primary,
        }
    },
    errorMessage: {
        color: theme.palette.primary.red,
        padding: 0,
        marginBottom: 10,
    },
}));

const TextArea = ({ id, className, errorMessage = '', placeholder = 'Enter text',label = null, ...rest }) => {
    const classes = useStyles();
    const inp = useRef();

    return (
        <div className={classes.root}>
           {label && <label htmlFor={id} className={classes.label}>{label}</label>}
            <div className={classes.inputWrapper}>
                <textarea
                    id={id}
                    ref={inp}
                    placeholder={placeholder}
                    className={`${classes.input} ${className || ''} ${errorMessage.length > 0 ? 'error' : ''}`}
                    {...rest}
                ></textarea>
                   
            </div>

           { <p className={classes.errorMessage}>{errorMessage}</p>}
        </div>
    );
};

export default memo(TextArea);
