import React, { memo, useEffect, useState } from 'react';
import Button from '../components/button';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import Nav from '../components/nav';
import TxnCard from '../components/txnCard';
import { coins, decryptWallet, getItem } from '../utils';
import Head from '../components/header';
import { getBTCTransactionHistory, getERC20TokenTransactionHistory, getTRXTransactionHistory, getUSDTTransactionHistory } from '../utils/endpoints';
import { commarize, getEthToUsdRate, USD, weiToUSDT } from '../utils/usdt/usdt';
import Spinner from '../components/spinner';
import { useTheme } from '@emotion/react';
import tx_icon from "../images/history.svg";
import moment from 'moment/moment';
import { DEMO_TXN } from './dasboard';
import NoTx from '../components/noTx';
import { tron_fromHex } from '../utils/tron/tron';
import useIsDesktop from '../customHooks/useDesktop';
import DesktopNav from '../components/desktopNav';
import Usercard from '../components/usercard';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'calc(100% - 50px)',
    padding: theme.spacing(1), width: '100%',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main, fontWeight: 600,
    },
    "@media (min-width: 1000px)":{
      overflowY:'scroll', height:'calc(100vh - 150px)', justifyContent: 'flex-start',
    }
  },
  txn: {
    display: 'flex', justifyContent: 'center', alignItems: 'flex-start',
    flexDirection: 'column', width: '100%', paddingBottom: theme.spacing(10),
    "@media (min-width: 1000px)":{
      maxWidth:1000
    }


  }
}));

export default memo(function TxnHistory() {
  const classes = useStyles();
  const nav = useNavigate();
  const [tx_temp, setTx_temp] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [address, setAddress] = useState('0x');
  const [selectedCoin, setSelectedCoin] = useState({});
  const theme = useTheme();

  const [txn, setTxn] = useState([]);
  const { coin } = useParams();
  const isDesktop = useIsDesktop();


  useEffect(() => {
    if (coins[coin.toUpperCase()]) {
      setSelectedCoin(coins[coin.toUpperCase()]);
      // Get token transaction history from the API here.
      const { Main, All } = getItem('__FLASHUSDT_U.WALLET__');
      if (Main) {

        const selected = (coin.toUpperCase() === 'USDT') ? Main : All.filter((t) => t.Type === coin.toUpperCase())[0];
        const addr = decryptWallet(selected.Wallet).address;
        setAddress(addr);
        if (coin === 'eth') {
          //get exchange rate btw eth and usd

          getERC20TokenTransactionHistory(addr).then((res) => {
            if (res.data.result.length > 0) {
              //word-break: break-all !important;
              setTxn(res.data.result);
            }

          }).catch((err) => {
            console.log(err);

          }).finally(() => {
            setShowLoader(false);
          });



        } else if (coin === 'usdt') {
          getUSDTTransactionHistory(addr).then((res) => {
            setTxn(res.data.data);
          }).catch((err) => {
            console.log(err);

          }).finally(() => {
            setShowLoader(false);
          });

        } else if (coin === 'trx') {
          getTRXTransactionHistory(addr).then((res) => {
            setTxn(res.data.data);
          }).catch((err) => {
            console.log(err);

          }).finally(() => {
            setShowLoader(false);
          });

        } else if (coin === 'btc') {
          getBTCTransactionHistory(addr).then((res) => {
            setTxn(res.data.txs);
          }).catch((err) => {
            console.log(err);

          }).finally(() => {
            setShowLoader(false);
          });
        }

      }

    } else {
      nav('/dashboard/coins');
    }
  }, [coin]);


  const exploreTxn = (tn, id) => {
    nav(`/dashboard/txn-detail?coin=${coin}`, { state: { tx: tn, txId: id, prevRoute: `/dashboard/txn/${coin}` } })
  };




  return (
    <div style={isDesktop ? { paddingTop: 0 } : { paddingTop: 36 }}> 
      {showLoader && <Spinner />}
      {!isDesktop && <Head backTo='/dashboard' currentPageTitle='Transaction History' />}

    {isDesktop &&  <><Usercard /><DesktopNav /></>}

      <div className={classes.root}>
        <div className={classes.txn}>
          {
            selectedCoin.symbol === 'ETH' && (txn.length > 0 ? txn.map((t, index) => <TxnCard
              key={index}
              amt={t?.value / Math.pow(10, selectedCoin?.decimals)}
              out={t?.from.toLowerCase() === address.toLowerCase()}
              date={moment((+t?.timeStamp) * 1000).format("MMM D, YYYY")}
              onClick={() => exploreTxn(t, t.hash)}
            />)
              :
              <NoTx />)
          }

          {
            (selectedCoin.symbol === 'BTC') && (txn.length > 0 ? txn.map((t, index) => {
              const from = t.inputs[0].addresses[0];
              const amount = t.outputs[0].value;

              return (<TxnCard
                key={index}
                amt={commarize(amount / Math.pow(10, selectedCoin?.decimals), 2, selectedCoin?.decimals || 8)}
                out={from.toLowerCase() === address.toLowerCase()}
                date={moment((t?.received)).format("MMM D, YYYY")}
                onClick={() => exploreTxn(t, t.hash)}
              />)
            })
              :
              <NoTx />)
          }


          {
            (selectedCoin.symbol === 'TRX') && (txn.length > 0 ? txn.map((t, index) => {
              const { amount, owner_address } = t.raw_data.contract[0].parameter.value;
              return (<TxnCard
                key={index}
                amt={commarize(amount / Math.pow(10, selectedCoin?.decimals), 2, selectedCoin?.decimals || 18)}
                out={tron_fromHex(owner_address).toLowerCase() === address.toLowerCase()}
                date={moment((+t?.block_timestamp)).format("MMM D, YYYY")}
                onClick={() => exploreTxn(t, t.txID)}
              />)
            })
              :
              <NoTx />)
          }

          {
            (selectedCoin.symbol === 'USDT') && (txn.length > 0 ? txn.map((t, index) => {
              return (<TxnCard
                key={index}
                amt={commarize(t.value / Math.pow(10, selectedCoin?.decimals), 2, selectedCoin?.decimals || 6)}
                out={t.from.toLowerCase() === address.toLowerCase()}
                date={moment((+t?.block_timestamp)).format("MMM D, YYYY")}
                className={(t?.from.toLowerCase() === address.toLowerCase()) ? 'show' : 'hide'}
                onClick={() => exploreTxn(t, t.transaction_id)}
              />)
            })
              :
              <NoTx />)
          }

        </div>

      </div>

    {!isDesktop &&  <Nav />}

    </div>
  );
});

