import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Head from '../components/header';
import { FetchNotification, ReadAllNotifications } from '../utils/endpoints';
import { getItem } from '../utils';
import moment from 'moment';
import Spinner from '../components/spinner';
import useIsDesktop from '../customHooks/useDesktop';
import Usercard from '../components/usercard';
import DesktopNav from '../components/desktopNav';


const useStyles = makeStyles((theme) => ({
    root:{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', padding:20, gap:10,
    },
    unread:{
        display:'flex',
        justifyContent:'space-between',
        padding:'20px 15px',
        backgroundColor:theme.palette.primary.tintMain,
        borderRadius:10,
        '& .body':{
            width:'calc(100% - 40px)',
            '& p':{
                color:theme.palette.text.secondary, margin:'5px 0 20px 0', fontSize:'13px',
            }
        },
        '& .title':{
            color:theme.palette.text.primary+'!important',
            fontSize:'16px',
            fontWeight:600+'!important',
        },
        '& .time':{
            color:theme.palette.text.secondary+' !important',fontSize:'13px',
        },
        '& .indicator':{
            backgroundColor:theme.palette.primary.green+'!important',
            width:8,height:8, borderRadius:20
        }
    },
    read:{
        display:'flex',
        justifyContent:'space-between',
        padding:'20px 15px',
        borderRadius:10,
        '& .body':{
            width:'calc(100% - 40px)',
            '& p':{
                color:theme.palette.text.secondary, margin:'5px 0 20px 0', fontSize:'13px',
            }
        },
        '& .title':{
            color:theme.palette.text.secondary,
            fontSize:'16px',
            fontWeight:500,
        },
        '& .time':{
            color:theme.palette.text.secondary,fontSize:'13px',
        },
        '& .indicator':{
            backgroundColor:theme.palette.primary.tintMain,
            width:8,height:8, borderRadius:20
        }
    },
    

       

}));

function Notification() {
    const [notifications, setNotifications] = useState([]);
    const [showLoader, setShowLoading] = useState(true);
    const [fromBadge, setFromBadge] = useState(null);
    const loc = useLocation();
    const isDesktop = useIsDesktop();


    useEffect(() => {
        setShowLoading(true);
        const uid = getItem('__FLASHUSDT__');
        FetchNotification(uid).then((res) => {
            setNotifications(res.data.data);
            ReadAllNotifications(uid);
            
        }).catch((err) => {
            console.log(err);
            
        }).finally(() => {
            setShowLoading(false);
        });
    },[])

    const param = new URLSearchParams(loc.search);
    useEffect(()=>{
        setFromBadge(param.get('from_badge'));
    },[])
    

    const classes= useStyles();

    return(
        <div style={isDesktop?{paddingTop:0}:{paddingTop:36}}>
            {showLoader  && <Spinner/>}
            {!isDesktop && <Head backTo='/dashboard' currentPageTitle='Notification'/>}
          {(isDesktop && fromBadge)&&  <>
            <Usercard DisableNotifBtn={true}/> <br />
            <DesktopNav/> <br />
          </>}
            <div className={classes.root} style={(isDesktop&&fromBadge)?{width:800,marginInline:'auto'}:{}}>
                {
                    notifications.map((item, index) => (
                        <div key={index} className={`${classes.read} ${classes[item.HasRead==1?'read':'unread']}`}>
                            <div className='body'>
                                <div className='title'>{item.Title}</div>
                                <p>{item.Content}</p>
                                <div className='time'>{moment(item.DateCreated).startOf('min').fromNow()}</div>
                            </div>
                            <div className='indicator'></div>
                        </div>
                    ))
                }

            </div>
        </div>
    );


}

export default Notification;