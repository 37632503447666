import React, { memo, useCallback, useEffect, useState } from 'react';
import Button from '../components/button';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import Nav from '../components/nav';
import nope from '../images/gradient-hate-emoji-illustration 1.svg';
import TxnCard from '../components/txnCard';
import { coins, decryptWallet, generateRandomNumber, getItem, handleError, saveItem } from '../utils';
import Head from '../components/header';
import CoinAmtCard from '../components/coinAmtCard';
import { checkTRC20Balance, checkUSDTBalance, tron_fromHex } from '../utils/tron/tron';
import Spinner from '../components/spinner';
import { checkERC20Balance, commarize} from '../utils/usdt/usdt';
import { getBTCBalance, getBTCTransactionHistory, getERC20TokenTransactionHistory, getTRXTransactionHistory, getUSDTTransactionHistory} from '../utils/endpoints';
import moment from 'moment';
import NoTx from '../components/noTx';
import Popup from '../components/popup';
import { useTheme } from '@emotion/react';
import useIsDesktop from '../customHooks/useDesktop';
import Usercard from '../components/usercard';
import DesktopNav from '../components/desktopNav';

const useStyles = makeStyles((theme) => ({
  root:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection:'column',
    height: 'calc(100% - 50px)',
    padding: theme.spacing(1), width:'100%',
    '& a':{
      textDecoration: 'none',
      color: theme.palette.primary.main, fontWeight:600,
    },
    '& .btn':{
      width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap:20,
       marginBottom:'20px',
      '& button':{
        '& svg path':{
          fill: '#fff'
        }

      },

      "@media screen and (min-width:1000px)":{
        margin:'2rem 0'
      }
    },
    "@media screen and (min-width:1000px)":{
    margin:'auto', width:'70%'
    }
  },

  txn: {
    display: 'flex',justifyContent: 'center', alignItems: 'flex-start',
    flexDirection: 'column', width:'100%', paddingBottom: theme.spacing(10),
    '& h3':{
      color: theme.palette.text.primary, fontWeight:700, fontSize:17, marginBottom:10,
    },
    '& .header':{
      display: 'flex',justifyContent: 'space-between', alignItems: 'center', width: '100%',
      color: theme.palette.text.secondary, fontSize:13, marginBottom:20,
    },
    '& .chart':{
      width:'100%',height:200,overflow: 'hidden',
      '& img': {
        width:'100%',height:'100%', objectFit: 'contain',
      },
      
    },
    "@media only screen and (min-width: 1000px)": {
       paddingBottom: theme.spacing(1),
    }
  }
    
  
}));
  
   
  

export default memo(function CoinView(){
  const arrowUp = (<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M12.7071 1.29289C13.0976 1.68342 13.0976 2.31658 12.7071 2.70711L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L11.2929 1.29289C11.6834 0.902369 12.3166 0.902369 12.7071 1.29289Z" fill="white"/>
    <path d="M12.2444 0.289816C12.525 0.371731 12.9065 0.515339 13.1956 0.804442C13.4847 1.09355 13.6283 1.47497 13.7102 1.75566C13.8008 2.06599 13.8605 2.41701 13.9018 2.77008C13.9845 3.4788 14.0051 4.31068 13.999 5.08421C13.9929 5.86374 13.9594 6.61224 13.9276 7.16387C13.9117 7.44031 13.8962 7.66877 13.8845 7.82876C13.8788 7.90804 13.8685 8.03764 13.865 8.08221L13.8649 8.08344C13.8185 8.63377 13.3347 9.04287 12.7844 8.99647C12.2341 8.95007 11.8256 8.46638 11.872 7.91609C11.8751 7.87652 11.8843 7.75946 11.8898 7.68378C11.9008 7.53234 11.9157 7.314 11.9309 7.04894C11.9615 6.51757 11.9933 5.80424 11.9991 5.06853C12.0049 4.32682 11.9839 3.59001 11.9153 3.002C11.8808 2.7067 11.8116 2.39695 11.7645 2.23556C11.6031 2.18846 11.2933 2.11924 10.998 2.08476C10.41 2.01611 9.67318 1.99512 8.93146 2.00092C8.19575 2.00668 7.48242 2.03846 6.95105 2.06904C6.68599 2.0843 6.46765 2.09917 6.31621 2.11017C6.24052 2.11567 6.12394 2.12485 6.08438 2.12797C5.53409 2.17431 5.04992 1.76584 5.00353 1.21554C4.95714 0.665204 5.36566 0.181463 5.916 0.135071L5.91828 0.134891C5.96383 0.1313 6.09259 0.121148 6.17125 0.115431C6.33124 0.103805 6.5597 0.0882541 6.83614 0.0723449C7.38778 0.0405985 8.13627 0.0070863 8.91581 0.00098511C9.68934 -0.00506911 10.5212 0.0155118 11.2299 0.0982586C11.583 0.139481 11.934 0.199252 12.2444 0.289816Z" fill="white"/>
    </svg>
    );
const arrowDown = (<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M1.79289 12.7071C1.40237 12.3166 1.40237 11.6834 1.79289 11.2929L12.7929 0.292893C13.1834 -0.0976315 13.8166 -0.0976315 14.2071 0.292893C14.5976 0.683417 14.5976 1.31658 14.2071 1.70711L3.20711 12.7071C2.81658 13.0976 2.18342 13.0976 1.79289 12.7071Z" fill="white"/>
  <path d="M2.25565 13.7102C1.97495 13.6283 1.59354 13.4847 1.30443 13.1956C1.01532 12.9064 0.87171 12.525 0.789795 12.2443C0.699231 11.934 0.639461 11.583 0.598241 11.2299C0.515499 10.5212 0.494926 9.68932 0.500989 8.91579C0.507098 8.13626 0.540619 7.38776 0.572372 6.83613C0.588284 6.55969 0.603839 6.33123 0.615467 6.17124C0.62123 6.09196 0.631451 5.96236 0.634966 5.91779L0.635062 5.91656C0.681461 5.36623 1.16525 4.95713 1.71559 5.00353C2.26589 5.04993 2.67439 5.53362 2.62805 6.08391C2.62493 6.12348 2.61571 6.24054 2.61021 6.31622C2.5992 6.46766 2.58432 6.686 2.56907 6.95106C2.53848 7.48243 2.50669 8.19576 2.50093 8.93147C2.49511 9.67318 2.5161 10.41 2.58475 10.998C2.61922 11.2933 2.68844 11.603 2.73554 11.7644C2.89693 11.8115 3.20669 11.8808 3.502 11.9152C4.09002 11.9839 4.82682 12.0049 5.56854 11.9991C6.30425 11.9933 7.01758 11.9615 7.54895 11.931C7.81401 11.9157 8.03235 11.9008 8.18379 11.8898C8.25948 11.8843 8.37606 11.8751 8.41562 11.872C8.96591 11.8257 9.45008 12.2342 9.49647 12.7845C9.54286 13.3348 9.13434 13.8185 8.584 13.8649L8.58172 13.8651C8.53617 13.8687 8.40741 13.8789 8.32875 13.8846C8.16876 13.8962 7.9403 13.9117 7.66386 13.9277C7.11222 13.9594 6.36373 13.9929 5.58419 13.999C4.81066 14.0051 3.97878 13.9845 3.27006 13.9017C2.917 13.8605 2.56597 13.8007 2.25565 13.7102Z" fill="white"/>
  </svg>
  );
  const theme = useTheme();
  const classes = useStyles();
  const nav = useNavigate();
  const {coin} = useParams();
  const [selectedCoin, setSelectedCoin]= useState({});
  const [walletInfo, setWalletInfo]= useState();
  const [showLoader, setShowLoader] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [userMainWallet, setUserMainWallet] = useState({});
  const [user, setUser] = useState({});
  const isDesktop = useIsDesktop();

    useEffect(() => {
      if(!coins[coin.toUpperCase()]){
        nav('/dashboard/coins');
        return;
      }

      const USER = getItem('__FLASHUSDT_USER__');
      if (!USER) {
        nav('/signin');
      } else {
        setUser(getItem('__FLASHUSDT_USER__'));
      }


      setSelectedCoin(coins[coin.toUpperCase()]);
      
      coinDetails();
    },[]);

    const confirmElegibility = useCallback((path,state)=> {
      if(user.AccountType !== 'FREE'){
        //proceed with the transaction
        nav(path,state);
      }else{
        setShowPopup(true);
      }
    });

    const coinDetails = ()=> {
      const {All,Main} = getItem('__FLASHUSDT_U.WALLET__');
      let userWallet = (coin.toUpperCase() === 'USDT')? Main: All.filter((t)=> t.Type === coin.toUpperCase())[0];

      if(!userWallet){
        nav('/dashboard/coins');
        return;
      }
      setUserMainWallet(decryptWallet(userWallet.Wallet));
      
      const {address,privateKey} = decryptWallet(userWallet.Wallet);      

      switch (coin) {
        case 'usdt':
         Promise.all([checkUSDTBalance(address, privateKey), getUSDTTransactionHistory(address)]).then(([bal,tx]) =>{
            let obj = {
              address,
              token:bal,
              rate:1,
              usd:1*bal,
              symbol:coins[coin.toUpperCase()]?.symbol,
              name:coins[coin.toUpperCase()]?.name,
              logo:coins[coin.toUpperCase()]?.logo
            };

            setTransactions(tx.data.data);

            setWalletInfo(obj);
            setShowLoader(false);

            
          });
          break;
          case 'trx':
              Promise.all([checkTRC20Balance(address),getTRXTransactionHistory(address,5)]).then(([bal,tx])=>{
                let obj = {address,...bal,symbol:coins[coin.toUpperCase()]?.symbol,name:coins[coin.toUpperCase()]?.name,logo:coins[coin.toUpperCase()]?.logo};
                setTransactions(tx.data.data);
  
                setWalletInfo(obj);
                setShowLoader(false);
            })
          break;
          
          case 'eth':
            Promise.all([checkERC20Balance(address),getERC20TokenTransactionHistory(address,5)]).then(([bal,tx])=>{
              let obj = {address,...bal,symbol:coins[coin.toUpperCase()]?.symbol,name:coins[coin.toUpperCase()]?.name,logo:coins[coin.toUpperCase()]?.logo};
              
              setTransactions(tx.data.result);

              setWalletInfo(obj);
              setShowLoader(false);
              
            }).catch(err=>{
              console.log(err);
            });
            break;

          case 'btc':
            Promise.all([getBTCBalance(address),getBTCTransactionHistory(address,5)]).then(([bal,tx])=>{
              let obj = {address,...bal,symbol:coin.toUpperCase(),name:coins[coin.toUpperCase()]?.name,logo:coins[coin.toUpperCase()]?.logo};
              
              setTransactions(tx.data.txs);

              setWalletInfo(obj);
              setShowLoader(false);
              
            }).catch(err=>{
              console.log(err);
            });
            break;

      }
    };
    const exploreTxn = (tn, id)=>{
      nav(`/dashboard/txn-detail?coin=${coin}`,{state:{tx:tn, txId:id,prevRoute:`/dashboard/coin-view/${coin}`}})
    };


  return (
    <div style={isDesktop ? { paddingTop: 0 } : { paddingTop: 36 }}>
    {showLoader && <Spinner/>}
    {!isDesktop && <Head backTo='/dashboard/coins' currentPageTitle={`${selectedCoin?.name} (${selectedCoin?.symbol})`}/>}

    {isDesktop && <><Usercard /><br /> <DesktopNav /></>}

      <div className={classes.root}> 

        <CoinAmtCard WalletData={walletInfo}/> 

      <div className={classes.txn}>
        {!isDesktop && <div className='chart'>
          <img src={`/line-chart${generateRandomNumber(1,4)}.svg`} alt=" " />
        </div>}

      <div className="btn">
        <Button text='Send' icon={arrowUp} 
          onClick={()=>confirmElegibility(`/dashboard/send/${selectedCoin?.symbol.toLowerCase()}`,{state:{prevRoute:`/dashboard/coin-view/${selectedCoin?.symbol.toLowerCase()}`}})}/>

        <Button text='Receive' icon={arrowDown} style={{backgroundColor:'#EDA833'}} 
          onClick={()=>confirmElegibility(`/dashboard/receive/${selectedCoin?.symbol.toLowerCase()}`,{state:{prevRoute:`/dashboard/coin-view/${selectedCoin?.symbol.toLowerCase()}`}})}/>
      </div>

      <h3>Recent Transactions</h3>
      <div className="header">
        <div>Transaction type</div>
        <div>Amount</div>
      </div>

      {
        selectedCoin.symbol === 'ETH' &&  (transactions.length > 0? transactions.map((t,index) => <TxnCard
          key={index}
           amt={t?.value/ Math.pow(10, selectedCoin?.decimals)}
           out={t?.from.toLowerCase() === userMainWallet?.address.toLowerCase()}
           date = {moment((+t?.timeStamp)*1000).format("MMM D, YYYY")}
           onClick={()=>exploreTxn(t, t.hash)}
           /> ) 
          :
         <NoTx/>)
        }


      {
       (selectedCoin.symbol === 'TRX') &&  (transactions.length > 0? transactions.map((t,index) => {
          const {amount,owner_address} = t.raw_data.contract[0].parameter.value;          
          return(<TxnCard
          key={index}
           amt={commarize(amount/ Math.pow(10, selectedCoin?.decimals),2,selectedCoin?.decimals || 18)}
           out={tron_fromHex(owner_address).toLowerCase() === userMainWallet?.address.toLowerCase()}
           date = {moment((+t?.block_timestamp)).format("MMM D, YYYY")}
           onClick={()=>exploreTxn(t,t.txID)}
           /> )}) 
          :
         <NoTx/>)
        }

      {
       (selectedCoin.symbol === 'USDT') &&  (transactions.length > 0? transactions.map((t,index) => {
          return(<TxnCard
          key={index}
           amt={commarize(t.value / Math.pow(10, selectedCoin?.decimals),2,selectedCoin?.decimals || 6)}
           out={t.from.toLowerCase() === userMainWallet?.address.toLowerCase()}
           date = {moment((+t?.block_timestamp)).format("MMM D, YYYY")}
           className={(t?.from.toLowerCase() === userMainWallet?.address.toLowerCase())?'show':'hide'}
           onClick={()=>exploreTxn(t,t.transaction_id)}
           /> )}) 
          :
         <NoTx/>)
        }


      {
        (selectedCoin.symbol == 'BTC') &&  (transactions.length > 0? transactions.map((t,index) => {
          const from = t.inputs[0].addresses[0];
          // const to = transactions.outputs[0].addresses[0];
          const amount = t.outputs[0].value; 

          return(<TxnCard
          key={index}
           amt={commarize(amount/ Math.pow(10, selectedCoin?.decimals),2,selectedCoin?.decimals || 8)}
           out={from.toLowerCase() === userMainWallet?.address.toLowerCase()}
           date = {moment((t?.received)).format("MMM D, YYYY")}
           onClick={()=>exploreTxn(t,t.hash)}
           /> )}) 
          :
         <NoTx/>)
        }

          {
            transactions.length >= 4 && <div style={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center'}}>
            <Button text='View all transactions' style={{marginTop:14}} onClick={()=>nav(`/dashboard/txn/${selectedCoin.symbol.toLowerCase()}`,{state:{prevRoute:`/dashboard/coin-view/${selectedCoin.symbol.toLowerCase()}`}})}/> 
            </div> 
          }


      </div>

     

    </div>
    
   {!isDesktop && <Nav/>}
    <Popup display={showPopup} setDisplay={setShowPopup}>
      <img src={nope} alt="No tx illustration" />
      <div style={{fontSize:12,fontWeight:400,textAlign:'center', margin:'15px 0',color:theme.palette.text.secondary}}>Your account is inactive. You can not perform transaction yet. Get a license key to activate your account and perform transactions. </div>

      <Button text='Get License key' onClick={()=>nav('/dashboard/activation',{state:{prevRoute:'/dashboard'}})}/>
  
    </Popup>
      
    </div>
  );
});

