import React, { memo, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Head from '../components/header';
import Input from '../components/input';
import Button from '../components/button';
import { CreateUserByEmail } from '../utils/endpoints';
import PinBox from '../components/pinBox';
import { handleError, saveItem } from '../utils';
import { useNavigate } from'react-router-dom';
import StartScreen from './startScreen';
import useIsDesktop from '../customHooks/useDesktop';
import DesktopFooter from '../components/desktopFooter';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', userSelect:'none', paddingTop:'4rem', position: 'relative',
        '@media (min-width:1000px)':{
        width: '70%', marginTop: '-10rem',paddingTop:'0', height: '100vh', justifyContent: 'center',
        },
        '& .full-width':{
            width: '100%', display: 'flex',flexDirection: 'column',alignItems: 'center', justifyContent: 'center',
        }
    },
    titleSection: {
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        '& h3': {
            color: theme.palette.text.primary,
        },
        '& p': {
            color: theme.palette.text.secondary,
            padding: theme.spacing(1, 2),
            fontSize: '14px',
        },
    },
    loginSection: {
        marginTop: theme.spacing(10),color: theme.palette.text.primary,cursor:'pointer',
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            fontSize: '16px',
            fontWeight: 'bold',
        },
    },
}));

export default memo(function EmailScreen() {
    const classes = useStyles();
    const [emailError, setEmailError] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [btnLoading, setBtnLoading] = useState(false);


    const handleChange = useCallback((e) => {
        setEmail(e.target.value);
    }, []);

    const processEmail = useCallback(() => {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        if (!emailRegex.test(email)) {
            setEmailError('Invalid email address');
        } else {
            setEmailError('');
            proceedSubmission();
        }
    }, [email]);

    const proceedSubmission = useCallback( async () => {
        setBtnLoading(true);
        CreateUserByEmail({Email: email}).then((res) => {
            const id = res.data?.data?.userId;
            saveItem('__FLASHUSDT__',id);
            navigate('/signup/verify');
        }).catch((e) => {
            setEmailError(handleError(e));
        }).finally(() => {
            setBtnLoading(false);
        });
     
    }, [email]);

    const isDesktop = useIsDesktop();

    useEffect(() => {
        if (isDesktop) {
            document.body.style.padding = 0;
        }else{
            document.body.style.padding = '20px';
        }
    }, [isDesktop])



    return (
        <div className={isDesktop ?classes.wrapper:''}>
            {isDesktop && <StartScreen/>}
            <div className={classes.root}>
                <div className={classes.titleSection}>
                    <h3>Enter Email Address</h3>
                    <p>We will send you a verification code</p>
                </div>

                <div className='full-width'>
                    <Input label='Email address' type='email' value={email} errorMessage={emailError} onChange={handleChange} />
                    <Button loading={btnLoading} text='Send Code' onClick={processEmail} />
                </div>

                <div className={classes.loginSection}>
                    <p>Already have an account? <a onClick={()=>navigate('/signin')}>&nbsp; Login</a></p>
                </div>
                {isDesktop && <DesktopFooter style={{transform:'translate(-50%,4rem)'}}/>}
            </div>
        </div>
    );
});