import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Head from '../../components/header';
import Button from '../../components/button';
import Input from '../../components/input';
import useIsDesktop from '../../customHooks/useDesktop';
import DesktopNav from '../../components/desktopNav';
import Usercard from '../../components/usercard';




const useStyles = makeStyles((theme) => ({
    root:{
        overflowY: 'scroll',
        display:'flex', alignItems: 'center', flexDirection: 'column',
        justifyContent:'center',gap:20, marginTop:'2rem',
        "@media (min-width:1000px)": {
            width: '1000px', marginInline:'auto',backgroundColor:theme.palette.background.default,
            padding:'2rem',borderRadius:20, minHeight:300, marginTop: 0,
        }
    },
    info__:{
        fontSize: '12px'
    }
 
       

}));


  

export default memo(function ImportWallet({setUserTheme=()=>null}) {
    const [inputMessage, setInputMessage] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [btnLoading, setBtnLoading] = useState('');
    const classes= useStyles();
    const isDesktop = useIsDesktop();


    const processForm=useCallback(()=>{
        if(inputValue.trim().length < 10){
            setInputMessage("Invalid phrase");
        }else{
            setInputMessage("");
            // submitCode();
        }        
    },[inputValue])




    return(
        <div style={isDesktop ? { paddingTop: 0 } : { paddingTop: 36 }}>
           {!isDesktop && <Head backTo='/dashboard/settings' currentPageTitle='Import wallet'/>}

            {isDesktop && <><Usercard /><br /><DesktopNav /><br /></>}


            <div className={`${classes.root}`}>
            {isDesktop && <h4 style={{marginBottom:20}}>Import Wallet</h4>}

                <Input  id={'4ed3c32'} label='Secret Phrase' value={inputValue} onChange={(e)=>setInputValue(e.target.value)} placeholder='Paste your mneomonic phrase here' showIcon={false} errorMessage={inputMessage}/>
                <Button text='Import wallet'  onClick={processForm} loading={btnLoading} />
            </div>
        </div>
    );


})