import React, { memo, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Head from '../components/header';
import { CreateUserByEmail, CreateWalletSnapShot, PinResending, ProfileUpdating, VerifyEmail } from '../utils/endpoints';
import PinBox from '../components/pinBox';
import { encodeMnemonic, generateRandomNumber, getItem, handleError, saveItem, useCountdown } from '../utils';
import { useNavigate } from'react-router-dom';
import Info from '../components/info';
import Button from '../components/button';
import { createEthereumWallet } from '../utils/usdt/usdt';
import { createTRC20Wallet } from '../utils/tron/tron';
import md5 from 'md5';
import DesktopFooter from '../components/desktopFooter';
import useIsDesktop from '../customHooks/useDesktop';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex', marginTop: '60px',
        flexDirection: 'column',
        alignItems: 'center',
        "@media screen and (min-width:1000px)":{
            height: '100vh',justifyContent: 'center',marginTop: '-10rem'
        }
    },
    bodyCont:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', gap:60
    },
    titleSection: {
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        '& h3': {
            color: theme.palette.text.primary,
        },
        '& p': {
            color: theme.palette.text.secondary,
            padding: theme.spacing(1, 2),
            fontSize: '14px',
        },
    },
    loginSection: {
        marginTop: theme.spacing(10),color: theme.palette.text.primary,
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            fontSize: '14px',
            fontWeight: 'bold',
        },
    },
}));
function TxnPin(){
    const classes = useStyles();
    const navigate = useNavigate();

    const [info, setInfo] = useState({type:'error', message:'',status:false});
    const [pinValue, setPinValue] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const isDesktop = useIsDesktop();


    useEffect(() => {
        if(pinValue.length === 4) {
            setBtnDisabled(false);
        }else{
            setBtnDisabled(true);
        }        
    },[pinValue,btnDisabled]);

    const createWallet = ()=> {
     //1. Create wallet, encrypt save wallet information in  backend

      const encryptedWalletString = createTRC20Wallet();

       const payload = {
        Wallet:encryptedWalletString,
        Main:1,
        Type:'USDT'
       };
       
        const uid = getItem('__FLASHUSDT__');
        CreateWalletSnapShot(uid,payload).then((res)=>{
            saveItem('__FLASHUSDT_U.WALLET__',res.data.data);
            setBtnLoading(false);
            //3.  navigate to dashboard
            navigate('/dashboard');

        }).catch((e)=>{
            setBtnLoading(false);
            console.log(handleError(e));
        })
         
    }

    const proceedSubmission = useCallback(async () => {
        if(pinValue.length >= 4 && !btnLoading){
            setBtnLoading(true);
            const uid = getItem('__FLASHUSDT__');
            ProfileUpdating({TxnPin: md5(pinValue),Photo:`avater${generateRandomNumber(1)}.jpg`},uid).then((res) => {
                saveItem('__FLASHUSDT_USER__',res.data.data);
                createWallet();
                
            }).catch((e) => {
                setBtnLoading(false);
                setInfo({...info,status:true,message:handleError(e)});
            });
        }        
    },[pinValue,btnLoading]);


    return (
        <>
          {!isDesktop&& <Head backTo='/signup/profile' currentPageTitle='Transaction Pin' />}

            <div className={classes.root}>
            
                <div className={classes.titleSection}>
                    <h3>Create Transaction Pin</h3>
                    <p>Create a digit a 4 digit pin number to be<br/> used  to sign transactions</p>
                </div>

            <Info type={info.type} autoRemove={true} display={info.status} setDisplay={(st)=>setInfo({...info,status:st})}>{info.message}</Info>


            <div className={classes.bodyCont}>
                <PinBox type='password' getValue={setPinValue}/>
                <Button text='Create' disabled={btnDisabled} loading={btnLoading} onClick={proceedSubmission}/>
            </div>
            {isDesktop && <DesktopFooter/>}
            </div>
        </>
    );

}
export default memo(TxnPin);