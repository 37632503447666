import { useState, useEffect } from 'react';

const useIsDesktop = (width=1000) => {
  const [isDesktop, setIsDesktop] = useState(window.matchMedia(`(min-width: ${width}px)`).matches);

  useEffect(() => {
    if (isDesktop) {
      document.body.style.padding = 0
    } else {
      document.body.style.padding = '20px';
    }
  }, [isDesktop])

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(min-width: ${width}px)`);

    const handleChange = () => {
      setIsDesktop(mediaQuery.matches);

    };

    mediaQuery.addEventListener('change', handleChange);

    handleChange();

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [width]);

  return isDesktop;
};

export default useIsDesktop;