import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import demo from '../images/demo.svg'
import inactive from '../images/inactive.svg'
import premium from '../images/premium.svg'
import { globals } from '../utils/themes';
import { generateRandomNumber, getItem } from '../utils';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin: '10px 0', fontWeight: 600, backgroundColor: theme.palette.primary.tintMain, padding: '12px 21px 12px 12px', borderRadius: 24, fontSize: 12,
        "@media only screen and (min-width: 1000px)": {
            border: '0.8px solid #d3d3d399'
        }
    },

    usercard: {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        gap: 10,
        '& .photo': {
            width: '45px',
            height: '45px',
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            borderRadius: '50%',
            '& img':{
                width:'100%',
            }
        },
        '& h4': {
            color: theme.palette.text.primary,
        },
        '& p': {
            color: theme.palette.text.secondary, fontWeight: 400, fontSize: 12, whiteSpace: 'nowrap'
        },
        '& .info': {
            //    minWidth:40,
        }
    },
    amts: {
        color: theme.palette.text.primary, display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', flexDirection: 'column', gap: 5, fontSize: 11

    },
    chart: {
        color: theme.palette.text.primary, display: 'flex', alignItems: 'center', justifyContent: 'center',
        paddingRight: '10px',
        '& img': {
            width: '100%',
            height: '100%',
        }

    },
    debit: {
        color: theme.palette.primary.red
    }


}));

export default memo(function WalletCard({ descr = 'Bitcoin', code = 'usdt', rawAmt = '0', convtAmt = '$0', onClick, style}) {
    const classes = useStyles();
    return (
        <div className={`${classes.root}`} tabIndex={-1} role='button' onClick={onClick} style={style}>
            <div className={classes.usercard}>
                <div className='photo'>
                    <img src={`/${code.toLowerCase()}.svg`} alt="" />
                </div>
                <div className='info'>
                    <h4>{code.toUpperCase()}</h4>
                    <p>{descr}</p>
                </div>
                <div className={classes.chart} style={{ textAlign: 'right' }}>
                    <img src={`/line-chart${generateRandomNumber(1, 4)}.svg`} alt="" />
                </div>
            </div>


            <div className={classes.amts}>
                <div>{rawAmt}</div>
                <div style={{ fontWeight: 400 }}>{convtAmt}</div>
            </div>

        </div>
    );


})