import React, { memo, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Head from '../components/header';
import Input from '../components/input';
import Button from '../components/button';
import { CreateUserByEmail, ProfileUpdating } from '../utils/endpoints';
import PinBox from '../components/pinBox';
import { generateRandomNumber, getItem, handleError, saveItem } from '../utils';
import { useNavigate } from'react-router-dom';
import Info from '../components/info';
import useIsDesktop from '../customHooks/useDesktop';
import DesktopFooter from '../components/desktopFooter';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .full-width':{
            width: '100%', display: 'flex',flexDirection: 'column',alignItems: 'center', justifyContent: 'center',
        },
        "@media screen and (min-width:1000px)":{
            height: '100vh',justifyContent: 'center',marginTop: '-10rem'
        }
    },
    titleSection: {
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        '& h3': {
            color: theme.palette.text.primary,
        },
        '& p': {
            color: theme.palette.text.secondary,
            padding: theme.spacing(1, 2),
            fontSize: '14px',
        },
    },
    loginSection: {
        marginTop: theme.spacing(3),
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            fontSize: '14px',
            fontWeight: 'bold',
        },
    },
}));

export default memo(function ProfileSetup() {
    const classes = useStyles();
    const [btnLoading, setBtnLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [FnError, setFnError] = useState('');
    const [PwdError, setPwdError] = useState('');
    const [Cpwderror, setCPwdError] = useState('');
    const [formData, setFormData] = useState({FirstName: '', Password: '',ConfirmPassword: ''});
    const [info, setInfo] = useState({type:'error', message:'',status:false});
    const [showLoader, setShowLoading] = useState(false);
    const isDesktop = useIsDesktop();


    const navigate = useNavigate();


    const processForm = useCallback(() => {    
        if(!btnLoading){
        if (formData.FirstName.trim().length === 0) {
            setFnError('Please enter your name');
            return;
        }else{
            setFnError('');
        }

        if (formData.Password.trim().length === 0) {
            setPwdError('Create a password');
            return;
        }else{
            setPwdError('');
        }

        if (formData.Password.trim().length < 8) {
            setPwdError('Password should be at least 8 characters');
            return;
        }else{
            setPwdError('');
        }

        if (formData.ConfirmPassword.trim().length ===0) {
            setCPwdError('Please confirm your password');
            return;
        }else{
            setCPwdError('');
        }

        if (formData.Password !== formData.ConfirmPassword) {
            setCPwdError('Password mismatch');
            return;
        }else{
            setCPwdError('');
        }

        proceedSubmission();
    }

    }, [formData.FirstName,formData.Password,formData.ConfirmPassword,btnLoading]);

    useEffect(() => {
        if (formData.FirstName.trim().length > 0 && formData.Password.trim().length > 0 && formData.ConfirmPassword.trim().length > 0) {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true);
        }
        
    }, [formData.FirstName,formData.Password,formData.ConfirmPassword,btnLoading]);

    const proceedSubmission = useCallback( async () => {
        setBtnLoading(true);

        const fullname = formData.FirstName;
        const payload = {
            Surname: fullname.split(' ')?.[1] || '',
            FirstName: fullname.split(' ')[0],
            Password: formData.Password,
            //generate random password - 1111111223
            OnboardingKey:'USER_'+generateRandomNumber(10000,99999)
        }


        const uid = getItem('__FLASHUSDT__');
        
        ProfileUpdating(payload,uid).then((res) => {
            // const id = res.data?.data?.userId;
            navigate('/signup/txnpin');
        }).catch((e) => {
            setInfo({message:handleError(e),type: 'error', status: true});
        }).finally(() => {
            setBtnLoading(false);
        });
        
    }, [formData.ConfirmPassword, formData.FirstName,btnLoading, formData.Password]);


    return (
        <div style={{paddingTop:36}}>
            {!isDesktop && <Head backTo='/signup' currentPageTitle='C  reate Profile' />}

            <div className={classes.root}>
                <div className={classes.titleSection}>
                    <h2>Create Profile</h2>
                    <p>Set up profile. Don't worry, you can <br/> always change it later.</p>
                </div>

                <Info type={info.type} autoRemove={true} display={info.status} setDisplay={(st)=>setInfo({...info,status:st})}>{info.message}</Info>

                <div className='full-width'>
                    <Input id={'s3d'+1} label='Your Name' showIcon={false} placeholder='Enter your name' value={formData.FirstName} errorMessage={FnError} onChange={(e)=>setFormData({...formData,FirstName:e.target.value})} />

                    <Input  id={'swd'+2} label='Create Password' type='password' placeholder='Minimum of 8 character' value={formData.Password} errorMessage={PwdError} onChange={(e) =>setFormData({...formData,Password:e.target.value})} />

                    <Input id={'sdd'+3} label='confirm Password' type='password'  placeholder='Enter password again' value={formData.ConfirmPassword} errorMessage={Cpwderror} onChange={(e) =>setFormData({...formData,ConfirmPassword:e.target.value})} />


                    <Button text='Submit' onClick={processForm} disabled={btnDisabled} loading={btnLoading}/>
                </div>
                {isDesktop && <DesktopFooter/>}
                
            </div>
            
        </div>
    );
});