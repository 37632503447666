import React, { memo, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Head from '../components/header';
import Input from '../components/input';
import Button from '../components/button';
import { CreateUserByEmail, FetchUserWallets, Login } from '../utils/endpoints';
import PinBox from '../components/pinBox';
import { getItem, handleError, saveItem } from '../utils';
import { useNavigate } from'react-router-dom';
import Info from '../components/info';
import Flyout from '../components/flyout';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',flexDirection: 'column',alignItems: 'center',
        userSelect:'none',
        
        marginTop: '4rem',
        '& > div':{
            width:'100%',
            maxWidth: '500px',
            display: 'flex',flexDirection: 'column',alignItems: 'center', justifyContent: 'center',
        }
    },
    titleSection: {
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        '& .grey':{
            color: theme.palette.text.secondary,
        },
        '& h3': {
            color: theme.palette.text.primary,
        },
        '& p': {
            color: theme.palette.text.secondary,
            padding: theme.spacing(1, 2),
            fontSize: '14px',
        },
    },
    loginSection: {
        marginTop: theme.spacing(10),color: theme.palette.text.primary,cursor:'pointer',
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            fontSize: '16px',
            fontWeight: 'bold',
        },
    },
    forgetPwdSection: {
        margin: '-10px 0 15px 0', width:'100%', display:'flex', justifyContent: 'flex-end', alignItems: 'center',
        '& span': {
            color: theme.palette.primary.main, fontSize: '14px',textDecoration: 'none',
           
           
        },
    },
}));

export default memo(function SignIn() {
    const classes = useStyles();
    const [emailError, setEmailError] = useState('');
    const navigate = useNavigate();
    const [PwdError, setPwdError] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [formData, setFormData] = useState({Email: '', Password: ''});
    const [info, setInfo] = useState({type:'error', message:'',status:false});
    const [User, setUser] = useState({});

    useEffect(() => {
        const userData = getItem('__FLASHUSDT_USER__');
        if (userData) {
            if (userData?.Email)setFormData({ Email: userData.Email });
            setUser(userData);
        }

    }, [])



    const processRequest = useCallback(() => {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        if (!emailRegex.test(formData.Email)) {
            setEmailError('Invalid email address');
        } else {
            setEmailError('');
            if(formData.Password.trim().length < 1) {
                setPwdError('Enter password');
            }else{
                setPwdError('');
                proceedSubmission();
            }
        }
  
        
    }, [emailError,formData.Email, formData.Password]);

    const proceedSubmission = useCallback(async () => {
        if(!btnLoading) {
            setBtnLoading(true);
            Login(formData).then((res) => {
                const data = res.data.data;
                saveItem('__FLASHUSDT_USER__',data);
                saveItem('__FLASHUSDT__',data.UID);
                return data.UID;
                
            }).then((uid) => {
                FetchUserWallets(uid).then((w) => {                    
                    const wallet = w.data.data;
                    saveItem('__FLASHUSDT_U.WALLET__',wallet);
                    navigate('/dashboard');
                })
            }).catch((e) => {
                setBtnLoading(false);
                setInfo({...info,message:handleError(e),type:'error',status:true});
            })
        }

    },[formData.Email, formData.Password]);



    return (
        <>
            <div className={classes.root}>
                <div className={classes.titleSection}>
                   {!User?.Email ?  <h3>Get started</h3> : <h3><span className="grey">Welcome back, </span> {User.FirstName}</h3>}
                    {!User?.Email ? <p>Enter Login details to access account</p>:  <p>Enter your password to proceed</p>}
                </div>

                <Info type={info.type} autoRemove={true} display={info.status} setDisplay={(st)=>setInfo({...info,status:st})}>{info.message}</Info>

                <div>
                    {!User?.Email && <Input label='Email address' type='email' placeholder='Enter email address' value={formData.Email} errorMessage={emailError} onChange={(e)=>setFormData({...formData,Email:e.target.value})} />}

                    <Input label='Password' type='password' placeholder='Enter password' errorMessage={PwdError} value={formData.Password} onChange={(e)=>setFormData({...formData,Password:e.target.value})} />

                    <div className={classes.forgetPwdSection}>
                        <span href='/forget-password' tabIndex={-1} role='button' onClick={()=>navigate('/forget-password')}>Forget Password?</span>
                    </div>

                    <Button text='Sign In' onClick={processRequest} loading={btnLoading}/>
                </div>

                <div className={classes.loginSection}>
                    <p>Don't have an account? <a onClick={()=>navigate('/signup')}>&nbsp; Sign up</a></p>
                </div>
            </div>
      
        </>
    );
});