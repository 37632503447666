import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    tabs: {
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        '& > div': {
            display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 13px', backgroundColor: theme.palette.primary.tintMain,
            borderRadius: '16px', cursor: 'pointer', gap: 10, minWidth: 133, color: theme.palette.text.primary,
            '& path': {
                fill: theme.palette.text.primary,
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                '& span': {
                    color: '#fff',
                },
                '& path': {
                    fill: '#fff'
                }
            }
        },
        '& .active': {
            backgroundColor: theme.palette.primary.main,
            '& span': {
                color: '#fff',
            },
            '& path': {
                fill: '#fff',
            }
        }
    }

}));


export default memo(function Nav() {
    const classes = useStyles();
    const el = useRef(null);
    const nav = useNavigate();
    const { coin } = useParams();


    return (

        <div ref={el} className={`${classes.tabs}`}>
            <div title="Dashboard" tabIndex={-1} role="button" className={window.location.pathname === '/dashboard' ? 'active' : ''} onClick={() => nav('/dashboard')} >
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M11 0.75C10.1339 0.75 9.35968 1.02688 8.52381 1.49594C7.7139 1.95043 6.78587 2.62145 5.61887 3.46524L4.10984 4.55632C3.1733 5.23347 2.42528 5.7743 1.86109 6.27487C1.27855 6.79174 0.842739 7.307 0.566298 7.95514C0.289254 8.60469 0.222248 9.27045 0.259413 10.0381C0.295279 10.7789 0.432639 11.6727 0.603927 12.7872L0.919183 14.8387C1.16256 16.4225 1.35635 17.6837 1.64105 18.6662C1.93544 19.6821 2.35016 20.4887 3.0914 21.1052C3.82958 21.7192 4.70891 21.9926 5.78306 22.1231C6.828 22.25 8.14615 22.25 9.8111 22.25H12.1889C13.8539 22.25 15.172 22.25 16.217 22.1231C17.2911 21.9926 18.1704 21.7192 18.9086 21.1052C19.6499 20.4887 20.0646 19.6821 20.359 18.6662C20.6437 17.6837 20.8375 16.4225 21.0808 14.8387L21.3961 12.7871C21.5674 11.6726 21.7047 10.7789 21.7406 10.0381C21.7778 9.27045 21.7108 8.60469 21.4337 7.95514C21.1573 7.307 20.7215 6.79174 20.1389 6.27487C19.5747 5.7743 18.8267 5.23347 17.8902 4.55633L16.3812 3.46525C15.2142 2.62146 14.2861 1.95043 13.4762 1.49594C12.6403 1.02688 11.8661 0.75 11 0.75Z" fill="#102848" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M11 13.5C11.5523 13.5 12 13.9477 12 14.5V17.5C12 18.0523 11.5523 18.5 11 18.5C10.4477 18.5 10 18.0523 10 17.5L10 14.5C10 13.9477 10.4477 13.5 11 13.5Z" fill="#102848" />
                </svg>
                <span>Dashboard</span>

            </div>
            <div title="Coins" tabIndex={-1} role="button" onClick={() => nav('/dashboard/coins')} className={window.location.pathname === '/dashboard/coins' ? 'active' : ''} >
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M7.07686 3.71374C7.27748 3.25186 7.37779 3.02092 7.28894 2.88546C7.20009 2.75 6.96547 2.75 6.49624 2.75L3.68182 2.75001C2.06262 2.75001 0.750001 4.05957 0.750001 5.675L0.750001 13.5463C0.749976 15.3241 0.749956 16.7572 0.901836 17.8842C1.05952 19.0544 1.39687 20.0396 2.18119 20.8221C2.9655 21.6046 3.95303 21.9411 5.1259 22.0984C6.25559 22.25 7.69196 22.2499 9.47398 22.2499H15.478C16.8014 22.25 17.8927 22.25 18.7563 22.1341C19.6631 22.0125 20.4639 21.7473 21.1051 21.1076C21.7463 20.4679 22.012 19.669 22.134 18.7644C22.2501 17.9027 22.25 16.814 22.25 15.4937V13.4062C22.25 12.0859 22.2501 10.9972 22.134 10.1355C22.012 9.23091 21.7463 8.43198 21.1051 7.79227C20.4639 7.15257 19.6631 6.8874 18.7563 6.76578C18.1295 6.64275 16.3093 6.63732 15.4776 6.64998L3.68138 6.64998C3.14164 6.64998 2.7041 6.21346 2.7041 5.67499C2.7041 5.13651 3.14164 4.69999 3.68138 4.69999H6.27242C6.49012 4.69999 6.59897 4.69999 6.67738 4.64373C6.75579 4.58746 6.79287 4.4777 6.86705 4.25819C6.92959 4.07312 6.99965 3.89151 7.07686 3.71374Z" fill="#102848" />
                    <path d="M19 14.5C19 13.3954 18.1046 12.5 17 12.5C15.8954 12.5 15 13.3954 15 14.5C15 15.6046 15.8954 16.5 17 16.5C18.1046 16.5 19 15.6046 19 14.5Z" fill="#102848" />
                    <path d="M18.9557 5.53151C19.063 5.5463 19.1555 5.45355 19.1339 5.34742C18.6001 2.72413 16.2803 0.75 13.4994 0.75C10.8264 0.75 8.57932 2.57399 7.93503 5.04525C7.88746 5.22772 8.02962 5.40006 8.2182 5.40006L15.4679 5.40006C15.8968 5.39365 16.5712 5.39192 17.2232 5.40742C17.8197 5.42161 18.5159 5.45164 18.9557 5.53151Z" fill="#102848" />
                </svg>
                <span>Wallets</span>
            </div>
            <div title="Transactions" tabIndex={-1} role="button" onClick={() => nav('/dashboard/txn/usdt')} className={window.location.pathname === '/dashboard/txn/'+coin ? 'active' : ''} >
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M0.25 11.5C0.25 5.56294 5.06294 0.75 11 0.75C16.9371 0.75 21.75 5.56294 21.75 11.5C21.75 17.4371 16.9371 22.25 11 22.25C5.06294 22.25 0.25 17.4371 0.25 11.5Z" fill="#102848" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M11 6.5C11.5523 6.5 12 6.94772 12 7.5V11.0858L13.7071 12.7929C14.0976 13.1834 14.0976 13.8166 13.7071 14.2071C13.3166 14.5976 12.6834 14.5976 12.2929 14.2071L10.2929 12.2071C10.1054 12.0196 10 11.7652 10 11.5V7.5C10 6.94772 10.4477 6.5 11 6.5Z" fill="#102848" />
                </svg>
                <span>Transaction</span>
            </div>
            <div title="Profile" tabIndex={-1} role="button" onClick={() => nav('/dashboard/profile')} className={window.location.pathname === '/dashboard/profile' ? 'active' : ''} >
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.028 13.2677C15.5535 13.0091 16.1975 12.75 16.9993 12.75C17.8011 12.75 18.4453 13.0091 18.971 13.2676C19.1494 13.3554 19.3038 13.4363 19.4442 13.5099C19.7544 13.6725 19.996 13.7991 20.2769 13.8842C20.4566 13.9387 20.6443 13.9955 20.7752 14.0442C20.8874 14.086 21.106 14.1705 21.2761 14.35C21.426 14.5083 21.4969 14.6817 21.5373 14.8162C21.5707 14.9272 21.5988 15.0758 21.6186 15.1802C22.1761 18.1167 20.9481 20.956 17.9354 22.0518C17.6623 22.1513 17.3912 22.25 17.0007 22.25C16.6102 22.25 16.3391 22.1513 16.0659 22.0518C13.0533 20.9561 11.824 18.117 12.3814 15.1802C12.4012 15.0759 12.4293 14.9273 12.4626 14.8163C12.503 14.6818 12.5739 14.5084 12.7238 14.3501C12.8939 14.1706 13.1124 14.0861 13.2247 14.0443C13.3556 13.9956 13.5432 13.9387 13.723 13.8842C14.0037 13.7991 14.245 13.6726 14.555 13.51C14.6953 13.4364 14.8496 13.3555 15.028 13.2677Z" fill="#102848" />
                    <g opacity="0.4">
                        <path d="M10 0.75C7.10051 0.75 4.75 3.10051 4.75 6C4.75 8.8995 7.10051 11.25 10 11.25C12.8995 11.25 15.25 8.8995 15.25 6C15.25 3.10051 12.8995 0.75 10 0.75Z" fill="#102848" />
                        <path d="M12.7813 22.084C12.8642 21.918 12.6416 21.6221 12.1966 21.0303C10.9102 19.3197 10.4936 17.0892 10.9087 14.9013L10.9118 14.8849C10.9294 14.7913 10.9702 14.5743 11.0272 14.3847C11.105 14.1255 11.266 13.7092 11.6359 13.3186C11.6591 13.2941 11.6825 13.2704 11.7062 13.2475C11.8337 13.1237 11.7809 12.8874 11.6049 12.8625C10.5411 12.7125 9.4593 12.7125 8.39543 12.8625C6.93096 13.0691 5.49889 13.5602 4.19404 14.3372C4.07778 14.4064 3.93408 14.4875 3.77151 14.5792C3.0587 14.9813 1.98106 15.5893 1.2429 16.3118C0.781228 16.7637 0.342574 17.3592 0.262828 18.0888C0.178022 18.8646 0.516485 19.5927 1.1955 20.2396C2.36695 21.3556 3.77274 22.25 5.59105 22.25H11.6758C12.3575 22.25 12.6984 22.25 12.7813 22.084Z" fill="#102848" />
                    </g>
                </svg>
                <span>Profile</span>

            </div>
        </div>

    )
});