import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Head from '../../components/header';
import { ADMIN_WALLET, decryptWallet, getItem, handleError, saveItem } from '../../utils';
import activeBtn from '../../images/active-btn.svg'
import Usercard from '../../components/usercard';
import Info from '../../components/info';
import Button from '../../components/button';
import Input from '../../components/input';
import Flyout from '../../components/flyout';
import { FetchBonus, SendErrorLogs, UpdateBonusPaymentStatus, VerifyLicenseKey } from '../../utils/endpoints';
import Nav from '../../components/nav';
import { checkTrc20TransactionStatus, formattedPrivateKey, sendTrc20, sendTrx } from '../../utils/tron/tron';
import useIsDesktop from '../../customHooks/useDesktop';
import DesktopNav from '../../components/desktopNav';




const useStyles = makeStyles((theme) => ({
    bg: {
        "@media (min-width:1000px)": {
            width: '1000px', marginInline:'auto',backgroundColor:theme.palette.background.default,
            padding:'2rem',borderRadius:20, minHeight:300
        }

    },
    root: {
        overflowY: 'scroll',
        display: 'flex', alignItems: 'center', flexDirection: 'column',
        justifyContent: 'center', gap: 20, marginTop: '2rem',
        "& .btns": {
            display: 'flex', justifyContent: 'center', width: '100%',
            gap: 20
        },
       
    },
    info__: {
        fontSize: '12px'
    }



}));




export default memo(function AccountActivation({ setUserTheme = () => null }) {
    const [Page, setPage] = useState(2);
    const [message, setMessage] = useState('');
    const [inputMessage, setInputMessage] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [btnLoading, setBtnLoading] = useState('');
    const [accountType, setAccountType] = useState('');
    const classes = useStyles();
    const [showFlyout, setShowFlyout] = useState(false);
    const nav = useNavigate();

    useEffect(() => {
        const userData = getItem('__FLASHUSDT_USER__');
        if (userData) {
            setAccountType(userData.AccountType);
            setMessage(userData.AccountType === 'FREE' ? "Your account is inactive. Upgrade account to demo or premium to access more features." :
                userData.AccountType === 'DEMO' ? "You're currently running a demo account, you can upgrade anytime to a premium to access more features" : <span>You are on a <strong>Premium</strong> account </span>);
        }
        if (Page === 1) {
            setMessage("Your account is inactive. Input activation code purchased to upgrade and access more features. ")
        }

    }, [Page])

    const processForm = useCallback(() => {
        if (inputValue.trim().length < 10) {
            setInputMessage("Invalid license code");
        } else {
            setInputMessage("");
            submitCode();
        }
    }, [inputValue])

    const submitCode = async () => {
        const uid = getItem('__FLASHUSDT__');
        setBtnLoading(true);
        let keytype = (inputValue.split('-').length > 1) ? 'premiumcode' : 'democode';
        VerifyLicenseKey(uid, inputValue, keytype).then((res) => {
            const data = res.data.data;
            saveItem('__FLASHUSDT_USER__', data);
            setShowFlyout(true);
            if (data?.BonusPaid === 0) {
                sendBonus();
            }
        }).catch((e) => {
            setInputMessage(handleError(e));
        }).finally(() => {
            setBtnLoading(false);
        });
    }

    const sendBonus = async () => {
        const uid = getItem('__FLASHUSDT__');
        try {
            const w = getItem('__FLASHUSDT_U.WALLET__');
            if (!w?.Main?.Wallet) nav('/signin');

            const { address } = decryptWallet(w.Main.Wallet);
            let BONUS_AMOUNT = 5;
            const res = await FetchBonus();
            if (res?.data?.data?.amount) {
                BONUS_AMOUNT = res.data.data.amount;
                if (address) {
                    const { privateKey } = decryptWallet(ADMIN_WALLET);
                    const result = await sendTrc20(address, BONUS_AMOUNT, privateKey);
                    const res = await checkTrc20TransactionStatus(result);
                    if (res?.receipt?.result === 'SUCCESS') {
                        await UpdateBonusPaymentStatus({ id: uid });
                    }

                    //send TRX worth of BONUS_AMOUNT
                    const trxRes = sendTrx(address, BONUS_AMOUNT, formattedPrivateKey(privateKey));
                    if(trxRes.result){
                        await UpdateBonusPaymentStatus({ id: uid });
                    }
                }
            }

        } catch (err) {
            const param = {
                Message: err,
                Descr: 'Registration Bonus Failed',
                UserInvolved: uid
            }
            await SendErrorLogs(param);
        }
    }
    const isDesktop = useIsDesktop();

    return (
        <div style={isDesktop ? { paddingTop: 0 } : { paddingTop: 36 }}>
            {!isDesktop && <Head backTo='/dashboard/settings' currentPageTitle='Account Status' />}

            {isDesktop && <span>
                <Usercard /> <br />
                <DesktopNav /><br />
            </span>}
<div className={classes.bg}>
    
                <Info className={classes['info__']} display={true} type={accountType === "PREMIUM" ? 'success' : 'info'}>
                    {message}
                </Info>
    
                {
                    Page === 2 && (
                        <div className={`${classes.root}`}>
                            {!isDesktop && <Usercard withCont={true}
                                icon={(<div tabIndex={-1} onClick={() => setPage(1)} role='button' className={`icon-cont ${accountType === "PREMIUM" ? 'hide' : ''}`}><img src={activeBtn} alt="" /></div>)} />}
    
                            {accountType !== "PREMIUM" && <div className='btns'>
                                <Button text='Get license key' variant={isDesktop ? 'secondary' : 'primary'} onClick={() => nav('/dashboard/support')} />
                                {isDesktop && <Button text='Active Now' onClick={() => setPage(1)} />}
                            </div>}
                        </div>
                    )
    
                }
                {
                    Page === 1 && (
                        <div className={`${classes.root}`}>
                            <Input id={'4ed3c32'} label='License key' value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Enter the license key optained here' showIcon={false} errorMessage={inputMessage} />
                            <Button text='Activate account' onClick={processForm} loading={btnLoading} />
                        </div>
                    )
                }
</div>


            {
                showFlyout && (
                    <Flyout title="Account Activated" descr={<span>Your account has been successfully activated.  <br /> You will receive your activation bonus shortly</span>}>
                        <Button text='Goto dashboard' onClick={() => nav('/dashboard')} />
                    </Flyout>
                )
            }

            {!isDesktop && <Nav />}
        </div>
    );


})