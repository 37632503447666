import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Dashboard from "./modules/dasboard";
import StartScreen from "./modules/startScreen";
import EmailScreen from "./modules/boarding";
import { darkTheme, lightTheme } from "./utils/themes";
import { ThemeProvider } from "@mui/material";
import EmailVerification from "./modules/verifyEmail";
import TxnPin from "./modules/txnPin";
import SignIn from "./modules/signin";
import ResetPasswordFlows from "./modules/resetPassword";
import Profile from "./modules//settings/profile";
import NotificationPage from "./modules/notification";
import Settings from "./modules/settings";
import AccountActivation from "./modules/settings/accountStatus";
import ReCreatePassword from "./modules/settings/createNewPassword";
import SecuritySettings from "./modules/settings/security";
import CustomerSupport from "./modules/settings/customerSupport";
import SecretPhrase from "./modules/settings/secretPhrase";
import Nav from "./components/nav";
import ProfileSetup from "./modules/profileSetup";
import ImportWallet from "./modules/settings/importWallet";
import PluginUpload from "./modules/settings/uploadPlugin";
import PersonalServerActivation from "./modules/settings/personalServerActivation";
import { getItem } from "./utils";
import TxnHistory from "./modules/txnHistory";
import WalletCard from "./components/walletCard";
import CoinLists from "./modules/coinLists";
import CoinView from "./modules/coinView";
import TxDetail from "./modules/txDetail";
import WalletAddress from "./modules/walletAddr";
import InitCoinSending from "./modules/sendCoin";
import About from "./modules/settings/about";
import { Admin } from "./admin";
import useIsDesktop from "./customHooks/useDesktop";
import { FetchNotification, UpdateNotificationPushStatus } from "./utils/endpoints";
import Aside from "./components/aside";

function App() {
  const [theme, setTheme] = useState(lightTheme);
  const loc = useLocation();
  const isDesktop = useIsDesktop();
  const nav = useNavigate();

  const [show, setShow] = useState(false);
  const [remove, setRemove] = useState(true);
  useEffect(() => {
    let timer;
    if (show) {
      setRemove(false);
    }
    if (!show) {
      timer = setTimeout(() => {
        setRemove(true);
      }, 250);
    }


    return () => clearTimeout(timer);
  }, [show])

  useEffect(() => {

    //gett user theme from storage
    const storedTheme = getItem('__FLASHUSDT_U.THEME__');
    if (storedTheme) {
      !loc.pathname.includes('admin') && setTheme((storedTheme === 'dark' ? darkTheme : lightTheme));
    }

    // Set the body background color based on the current theme
    if (isDesktop && !loc.pathname.includes('admin')) {
      document.body.style.backgroundColor = theme.palette.background.desktop;
    } else {
      document.body.style.backgroundColor = theme.palette.background.default;
      const metaThemeColor = document.querySelector("meta[name='theme-color']");
      if (metaThemeColor) {
        metaThemeColor.setAttribute("content", theme.palette.background.default);
      }
    }


    //redirect user that did not login back to login
    // check if they attemt too get into dashboard pathname without signing in
    if (window.location.pathname.includes('dashboard')) {
      const userData = getItem('__FLASHUSDT_USER__');
      if (!userData) {
        window.location.href = '/signin';
        return;
      }
      if (+userData.LoggedIn !== 1) {
        window.location.href = '/signin';
      }
    }

  }, [theme, isDesktop]);


  useEffect(() => {
    const requestNotificationPermission = () => {
      if ('Notification' in window) {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            sendNotification();
          } else {
            console.log('Notification permission denied.');
          }
        });
      }
    }

    requestNotificationPermission();

  }, [])

  const sendNotification = async () => {

    const uid = getItem('__FLASHUSDT__');
    try {
      const res = await FetchNotification(uid);
      if (res.status === 200) {
        const data = res.data.data;
        data.forEach(async (not) => {
          if (not.HasRead == 0 && not.Pushed == 0) {
            const notif = new Notification(not.Title, {
              body: not.Content,
              icon: '/logo192.png'
            });
            await UpdateNotificationPushStatus(uid, not.ID);
            notif.onclick = () => {
              window.location.href = `${window.location.origin}/dashboard/notification/?from_badge=true`;
            }
            notif.onclose = async () => {
              await UpdateNotificationPushStatus(uid, not.ID);
            }
          }
        });
        setTimeout(() => { sendNotification(); }, 10000);
      }
    } catch (err) {
      console.log(err);
    }





  }





  return (
    <ThemeProvider theme={theme}>
      {!remove && isDesktop && <Aside setShowState={setShow} showState={show} />}
      <Routes>
        <Route path='/' element={isDesktop ?<SignIn />: <StartScreen />} />
        <Route path='signup' element={<EmailScreen />} />
        <Route path='signup/verify' element={<EmailVerification />} />
        <Route path='signup/profile' element={<ProfileSetup />} />
        <Route path='signup/txnpin' element={<TxnPin />} />
        <Route path='forget-password' element={<ResetPasswordFlows />} />
        <Route path='signin' element={<SignIn />} />
        <Route path='dashboard/*' element={<Nav />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='dashboard/profile' element={<Profile />} />
        <Route path='dashboard/notification' element={<NotificationPage />} />
        <Route path='dashboard/settings' element={<Settings setUserTheme={setTheme} />} />
        <Route path='dashboard/activation' element={<AccountActivation />} />
        <Route path='dashboard/reset-password' element={<ReCreatePassword />} />
        <Route path='dashboard/security' element={<SecuritySettings />} />
        <Route path='dashboard/support' element={<CustomerSupport />} />
        <Route path='dashboard/about' element={<About />} />
        <Route path='dashboard/phrase' element={<SecretPhrase />} />
        <Route path='dashboard/wallet-import' element={<ImportWallet />} />
        <Route path='dashboard/server-import' element={<PersonalServerActivation />} />
        <Route path='dashboard/txn/:coin' element={<TxnHistory />} />
        <Route path='dashboard/coins' element={<CoinLists />} />
        <Route path='dashboard/coin-view/:coin' element={<CoinView />} />
        <Route path='dashboard/txn-detail' element={<TxDetail />} />
        <Route path='dashboard/send/:coin' element={<InitCoinSending />} />
        <Route path='dashboard/receive/:coin' element={<WalletAddress />} />
        <Route path='dashboard/plugin' element={<PluginUpload />} />
        <Route path='admin' element={<Admin setUserTheme={setTheme} />} />
        <Route path='admin/pages/about' element={<Admin setUserTheme={setTheme} />} />
        <Route path='admin/pages/contact' element={<Admin setUserTheme={setTheme} />} />
        <Route path='admin/pages/activation-bonus' element={<Admin setUserTheme={setTheme} />} />
        <Route path='admin/pages/send-notifcation' element={<Admin setUserTheme={setTheme} />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
