import React, { useEffect, useState } from 'react';
import manageWallet from '../images/manageWallet.png';
import sendRecieve from '../images/sendnReceive.png';
import manageAssets from '../images/manageAssets.png';
import secureWallet from '../images/secureWallet.png';
import nextBtn from '../images/next-btn.svg';
import { styled } from '@mui/material';
import Button from '../components/button';
import { globals } from '../utils/themes';
import { useNavigate } from 'react-router-dom';
import { getItem } from '../utils';
import useIsDesktop from '../customHooks/useDesktop';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        height: 'calc(100vh - 100px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
        '@media (min-width:1000px)': {
            backgroundColor: '#102848', borderRadius: '0 0 10rem 0', width: '30%', height: '100vh', minWidth: 400, justifyContent: 'center',
            '& p, h2,h3': {
                color: 'white !important',
            },
            '& .welcome': {
                marginTop: '-300px', marginBottom: '200px',
            }

        },
        '& .skipbtn-cont': {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0 20px'
        },
        '& .skipbtn': {
            borderRadius: globals.borderRadius,
            backgroundColor: theme.palette.primary.lightBlue,
            color: theme.palette.primary.main,
            fontSize: '14px',
            cursor: 'pointer',
            border: 'none',
            padding: '7px 15px',
        },
        '& .controls': {
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 20px',
        },
        '& .welcome': {
            display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 20px', flexDirection: 'column',
            '& h2': {
                color: theme.palette.text.primary
            },
            '& p': {
                color: theme.palette.text.secondary,
                textAlign: 'center',
                padding: '10px 20px', fontSize: '14px'
            }
        },
        '& .cta': {
            display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 20px', flexDirection: 'column', gap: 10, marginTop: '-20px'
        },
        '& .slider': {
            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: 385, marginTop: '-40px',
            'img': {
                marginBottom: '20px',
            },
            '& h3': {
                color: theme.palette.text.primary
            },
            '& p': {
                color: theme.palette.text.secondary,
                textAlign: 'center',
                padding: '10px 20px', fontSize: '14px'
            }
        },
        '& .next-btn': {
            cursor: 'pointer',
        },
        '& .dots': {
            display: 'flex',
            gap: 5,
        },
        '& .dots .current': {
            backgroundColor: theme.palette.primary.main + ' !important'
        },
        '& .dots .dot': {
            width: 9,
            height: 9,
            borderRadius: 50,
            backgroundColor: theme.palette.primary.lightBlue
        }
    }


}));
function StartScreen() {
    const classes = useStyles();

    const slider = [
        {
            id: 1,
            title: "Easy Setup Guide",
            descr: 'With just two clicks, send Flash USDT crypto to over 40 wallets using our software',
            image: manageWallet,
        },
        {
            id: 2,
            title: "Fast Transactions to Any Wallet",
            descr: 'Quickly send verifiable USDT crypto to wallets and exchanges like Binance, ByBit, OKEx, KuCoin,casino platform and more.',
            image: sendRecieve,
        },
        {
            id: 3,
            title: "Full Transaction Confirmation",
            descr: 'Crypto from our software is fully verifiable, tradable, swappable, and supports P2P transactions.',
            image: manageAssets,
        },
        {
            id: 4,
            title: "Secure Wallet",
            descr: 'Protect your digital assets with advanced security features, including two-factor authentication, biometric access, and encrypted private keys.',
            image: secureWallet,
        },
    ];
    const [current, setCurrent] = useState(0);
    const navigate = useNavigate();
    const isDesktop = useIsDesktop();

    useEffect(() => {
        //move to the signin page if user already signed up
        if (!isDesktop) {
            const uid = getItem('__FLASHUSDT__');
            if (uid) navigate('/signin');
        }else{
            let timer;
            timer = setInterval(() => {
                setCurrent((cur)=>cur===slider.length-1? 0:cur+=1);
            },5000)
            
            return ()=> clearInterval(timer);
        }
    }, [isDesktop])


    return (
        <div className={`${classes.root}`}>
            {isDesktop && <div className="welcome"><h2 className="animated fadeInUp delay-0-3s">FlashUSDT</h2> </div>}
            {!isDesktop && (
                current === slider.length - 1 ? (<div className="welcome">
                    <h2 className="animated fadeInUp delay-0-3s">Welcome</h2>
                    <p className="animated fadeInUp delay-0-4s">Manage your crypto assets with Flash USDT Sender</p>
                </div>) :
                    (<div className="skipbtn-cont">
                        <button className='skipbtn' onClick={() => setCurrent(slider.length - 1)}>Skip</button>
                    </div>))
            }

            <div className='slider'>
                <img src={slider[current].image} width={300} alt={slider[current].title} className='animated slideInLeft' />
                <h3 className="animated fadeInUp delay-0-3s">{slider[current].title} </h3>
                <p className="animated fadeInUp delay-0-5s">{slider[current].descr} </p>
                {isDesktop && <div className='dots' style={{marginTop:30}}>
                    {Array.from(Array(slider.length).keys()).map((item, index) => <div key={index} className={`dot ${current === item ? 'current' : ''}`}></div>)}
                </div>}
            </div>
            {!isDesktop && (
                current === slider.length - 1 ? (
                    <div className='cta'>
                        <Button text='Sign In' className='animated fadeInUp delay-0-6s' onClick={() => navigate('/signin')} />
                        <Button text='Create Account' variant='secondary' onClick={() => navigate('/signup')} className='animated fadeInUp delay-0-7s' />
                    </div>
                ) : (
                    <div className="controls">
                        <div className='dots'>
                            {Array.from(Array(slider.length - 1).keys()).map((item, index) => <div key={index} className={`dot ${current === item ? 'current' : ''}`}></div>)}
                        </div>
                        <img src={nextBtn} className='next-btn' role='button' onClick={() => setCurrent((p) => p + 1)} />
                    </div>
                ))
            }


        </div>
    );


}

export default StartScreen;