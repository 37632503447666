import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { coins, decryptWallet, getItem} from '../utils';
import WalletCard from './walletCard';
import { checkTRC20Balance, checkUSDTBalance } from '../utils/tron/tron';
import Spinner from './spinner';
import { checkERC20Balance, USD } from '../utils/usdt/usdt';
import { getBTCBalance } from '../utils/endpoints';
import useIsDesktop from '../customHooks/useDesktop';

const useStyles = makeStyles((theme) => ({
  txn: {
    display: 'flex', justifyContent: 'center', alignItems: 'flex-start',
    flexDirection: 'column', width: '100%', paddingBottom: theme.spacing(10),
    "@media screen and (min-width:1000px)": {
      backgroundColor: theme.palette.background.default,
      width: '100%', maxWidth: 1200, padding: '2.5rem 4rem', borderRadius: '20px'
    }

  }

}));

export default memo(function CoinMap({ CoinLists = null, Callback = () => null,className= null,style}) {
  const classes = useStyles();
  const [userWallet, setUserWallet] = useState({});
  const [userWallet_Raw, setUserWallet_Raw] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const WalletObj = useRef({});
  const counter = useRef(0);
  const isDesktop = useIsDesktop();

  useEffect(() => {
    const { All } = getItem('__FLASHUSDT_U.WALLET__');
    if (All) {
      setUserWallet_Raw(All);

      All.forEach(({ Wallet, Type }) => {
        const { address, privateKey } = decryptWallet(Wallet);
        if (Type === "USDT") {
          checkUSDTBalance(address, privateKey).then((res) => {
            WalletObj.current[Type] = { address, token: res, rate: 1, usd: 1 * res };
            updateUserWallet();
          })

        }

        if (Type === "TRX") {
          if (counter.current === 0) {
            checkTRC20Balance(address).then((res) => {
              WalletObj.current[Type] = { address, ...res };
              updateUserWallet();
            })
          }
          counter.current += 1;
        }


        if (Type === "ETH") {
          checkERC20Balance(address).then((res) => {
            WalletObj.current[Type] = { address, ...res };
            updateUserWallet();
          })
        }

        if (Type === "BTC") {
          getBTCBalance(address).then((res) => {
            WalletObj.current[Type] = { address, ...res };
            updateUserWallet();
          })

        }

      });
    }

  }, []);



  const updateUserWallet = useCallback(() => {

    setUserWallet(prevState => ({
      ...prevState, ...WalletObj.current
    }));
    let s = Object.entries(WalletObj.current);

    if (s.length >= userWallet_Raw.length) {
      setShowLoader(false);
    }
  }, [WalletObj.current, userWallet_Raw]);



  return (
    <>
      {showLoader && <Spinner />}
      <div className={className?className:classes.txn}>
        { CoinLists ? CoinLists.map(({type:symbol}, i) => <WalletCard style={style} key={i} code={symbol} descr={coins[symbol].name} convtAmt={USD(userWallet[symbol]?.usd || 0)} rawAmt={userWallet[symbol]?.token} onClick={() => Callback(symbol)} />)
          :
          Object.entries(coins).map(([, { symbol, name }], i) => <WalletCard key={i} code={symbol} descr={name} convtAmt={USD(userWallet[symbol]?.usd || 0)} rawAmt={userWallet[symbol]?.token} onClick={() => Callback(symbol)} />)
        }
      </div>
    </>
  );
});

